<template>
  <van-grid :column-num="4" :gutter="10" :border="false" clickable>
    <van-grid-item
      v-for="item in list"
      :key="item.name"
      @click="handleItemClick(item)"
    >
      <div class="img-box">
        <van-image height="80" fit="contain" :src="item.logo" />
      </div>
      <div class="desc">
        <p>{{ item.name }}</p>
      </div>
    </van-grid-item>
  </van-grid>
</template>
<script setup>
defineProps({
  list: Object
})
const emit = defineEmits(['changeTV'])

const handleItemClick = (item) => {
  emit('changeTV', item)
}
</script>

<style lang="scss" scoped>
.van-grid {
  padding: 10px 0;
}
.desc {
  font-size: 12px;
  padding: 8px 3px;
  flex: 1;
  align-items: center;
  display: flex;
}
.img-box {
  width: 100%;
  padding: 0 3px;
  text-align: center;
  background-color: rgb(222, 229, 235);
}
::v-deep {
  .van-grid-item__content {
    padding: 0;
    justify-content: flex-start;
    border-radius: 2px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
}
</style>
