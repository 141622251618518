<template>
  <van-tabs v-model:active="active" swipeable>
    <van-tab v-for="item in data" :title="item.name" :key="item.name">
      <TabItem :list="item.list" @changeTV="handleChangeTV" />
      <p class="tip">*提示：部分频道可能无法正常播放~</p>
    </van-tab>
  </van-tabs>
</template>

<script setup>
import TabItem from './tab-item.vue'
import tvData from '@/data'

const emit = defineEmits(['changeTV'])
const handleChangeTV = (item) => {
  emit('changeTV', item)
}
const data = tvData
</script>

<style lang="scss" scoped>
::v-deep {
  .van-tab__panel {
    height: 300px;
    overflow: auto;
    height: calc(100vh - 288px);
    background: #f5f5f5;
  }
}
.tip {
  text-align: center;
  color: #999;
  font-size: 12px;
  padding-bottom: 10px;
}
</style>
