export default {
  name: '央视HD',
  list: [
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV1.png',
      name: 'CCTV-1 综合',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225654/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV2.png',
      name: 'CCTV-2 财经',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225676/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV3.png',
      name: 'CCTV-3 综艺',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225677/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV4.png',
      name: 'CCTV-4 中文国际',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225761/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV5.png',
      name: 'CCTV-5 体育',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225680/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV5+.png',
      name: 'CCTV-5+ 体育赛事',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225712/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV6.png',
      name: 'CCTV-6 电影',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225683/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV7.png',
      name: 'CCTV-7 国防军事',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225627/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV8.png',
      name: 'CCTV-8 电视剧',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225630/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV9.png',
      name: 'CCTV-9 纪录',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225663/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV10.png',
      name: 'CCTV-10 科教',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225704/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV11.png',
      name: 'CCTV-11 戏曲',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225750/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV12.png',
      name: 'CCTV-12 社会与法',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225670/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV13.png',
      name: 'CCTV-13 新闻',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225787/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV14.png',
      name: 'CCTV-14 少儿',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225673/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV15.png',
      name: 'CCTV-15 音乐',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225830/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    // {
    //   logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV16.png',
    //   name: 'CCTV-16 奥林匹克',
    //   url: 'https://live.olympicchannelchina.cn:443/aoyun/4kcctv16_1td.m3u8'
    // },
    // {
    //   logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV16.png',
    //   name: 'CCTV-16 奥林匹克 4K',
    //   url: 'http://yinhe-live.yinhe.vs.rxip.sc96655.com/live/CCTV-16-4K_8000.m3u8'
    // },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV17.png',
      name: 'CCTV-17 农业农村',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225766/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV4k.png',
      name: 'CCTV-4K',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225726/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    }
  ]
}
