import tvGangaotai from './tv-gangaotai' // 港澳台
// import tvYangshi from './tv-yangshi' // 央视
import tvYangshiHD from './tv-yangshi-HD' // 央视HD
// import tvWeishi from './tv-weishi' // 卫视
import tvWeishiHD from './tv-weishi-HD' // 卫视HD
import tvDifang from './tv-difang' // 地方
import tvShuzi from './tv-shuzi' // 数字
import tvHEVC from './tv-HEVC' // HEVC专区

import gbWangluo from './gb-wangluo' // 网络广播
import gbZonghe from './gb-zonghe' // 综合广播
import gbYunyue from './gb-yunyue' // 音乐广播

export default [
  tvYangshiHD,
  tvGangaotai,
  // tvYangshi,
  // tvWeishi,
  tvWeishiHD,
  tvDifang,
  tvShuzi,
  tvHEVC,
  gbWangluo,
  gbZonghe,
  gbYunyue
]
