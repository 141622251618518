
<template>
  <div class="player-box">
    <video
      class="player"
      ref="player"
      autoplay
      controls
      :src="tvObj.url"
      :style="{ backgroundImage: `url(${tvObj.logo})` }"
    >
      您的浏览器不支持 HTML5 video 标签。
    </video>
    <p v-if="loading" class="tip-text">加载中...</p>
    <p v-if="!loading && isErr" class="tip-text">{{ errText }}</p>
  </div>

  <TabBar @changeTV="handleChangeTV" />
</template>

<script setup>
// import SwipeBar from './components/swipe-bar'
import TabBar from './components/tab-bar'
import { ref, onMounted } from 'vue'
import tvData from '@/data'
import { isMobile } from '@/utils'
import { Notify } from 'vant'

const player = ref(null) // video元素
const tvObj = ref(tvData[0].list[0]) // 当前播放的视频对象
const loading = ref(false) // 视频加载中
const isErr = ref(false) // 是否加载出错
const errText = ref('加载失败，请重试') // 错误提示文本

onMounted(() => {
  if (!isMobile()) {
    Notify({ type: 'warning', duration: 8000, message: '抱歉，在PC端浏览器可能会播放失败，建议使用移动端浏览器播放。' })
  }
})

const handleChangeTV = (item) => {
  loading.value = true
  isErr.value = false

  const playerEl = player.value

  if (tvObj.value.name === item.name) {
    // 点击的是同一个视频
    playerEl.load() // 重新加载
  } else {
    tvObj.value = item
  }
  if (playerEl) {
    playerEl.oncanplay = () => {
      playerEl.play()
      loading.value = false
      console.log('可以播放了')
    }
  }

  setTimeout(() => {
    const { error } = playerEl
    console.log(error)
    if (error) {
      loading.value = false
      isErr.value = true
      if (error.code === 4 && error.message === '') {
        // errText.value = '抱歉，您的浏览器不支持播放。'
      }
    } else {

    }
  }, 1000)
}
</script>

<style lang="scss" scoped>
.player-box {
  background: #666;
  position: relative;
}
.player {
  width: 100%;
  height: 240px;
  background: center center no-repeat;
  background-size: 200px;
}
.tip-text {
  position: absolute;
  left: 0;
  top: 65%;
  text-align: center;
  color: #eee;
  width: 100%;
}
</style>
