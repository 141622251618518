
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import { isMobile } from '@/utils'

// Vant css
import 'vant/lib/index.css'

// 引入全局样式
import './styles/index.scss'

// 移动端控制台
import VConsole from 'vconsole'
if (isMobile()) {
  // eslint-disable-next-line no-new
  new VConsole()
}

const app = createApp(App)

app.use(Vant)
  .use(router)
  .mount('#app')
