
export default {
  name: '网络广播',
  list: [
    {
      logo: 'https://cmsres.dianzhenkeji.com//files/images/2017/12/14/1513241956312964.png',
      name: '潮流音悦台',
      url: 'http://stream3.hndt.com/now/Or5au0KN/playlist.m3u8'
    },
    {
      logo: 'http://cnvod.cnr.cn/audio2017/ondemand/img/1100/20190321/1553159793824.jpg',
      name: '动感音悦台',
      url: 'http://stream3.hndt.com/now/ufjjbZxV/playlist.m3u8'
    },
    {
      logo: 'https://cmsres.dianzhenkeji.com//files/images/2017/12/14/1513241973460940.png',
      name: '80后音悦台',
      url: 'http://stream3.hndt.com/now/SFZeH2cb/playlist.m3u8'
    },
    {
      logo: 'https://cmsres.dianzhenkeji.com//files/images/2017/12/14/1513241941253315.png',
      name: '经典FM',
      url: 'http://stream3.hndt.com/now/C5NvUpwy/playlist.m3u8'
    },
    {
      logo: 'http://cnvod.cnr.cn/audio2017/ondemand/img/1100/20190321/1553160555011.jpg',
      name: '格莱美音乐台',
      url: 'http://stream3.hndt.com/now/yorSd1X2/playlist.m3u8'
    },
    {
      logo: 'https://gitee.com/radioer/transparentlogo/raw/master/internetradio/快乐卷卷猫.png',
      name: '卷卷猫电台',
      url: 'http://stream3.hndt.com/now/PHucVOu2/playlist.m3u8'
    },
    {
      logo: 'https://cmsres.dianzhenkeji.com//files/images/2017/12/22/1513910910582954.png',
      name: '有声文摘',
      url: 'http://stream3.hndt.com/now/WNoVfBcQ/playlist.m3u8'
    },
    {
      logo: 'http://cnvod.cnr.cn/audio2017/ondemand/img/1100/20190321/1553157038145.jpg',
      name: '安全百科',
      url: 'http://stream3.hndt.com/now/4pcovD2L/playlist.m3u8'
    },
    {
      logo: 'https://cmsres.dianzhenkeji.com//files/images/2017/12/14/1513241902428118.png',
      name: '民谣音乐台',
      url: 'http://stream3.hndt.com/now/DTK5qc83/playlist.m3u8'
    },
    {
      logo: 'https://cmsres.dianzhenkeji.com/files/images/2017/12/14/1513241848889285.png',
      name: '天籁古典',
      url: 'http://stream3.hndt.com/now/MdOpB4zP/playlist.m3u8'
    },
    {
      logo: 'https://cmsres.dianzhenkeji.com/files/images/2017/12/14/1513241881377763.png',
      name: '摇滚天空台',
      url: 'http://stream3.hndt.com/now/SXJtR4M4/playlist.m3u8'
    },
    {
      logo: 'https://cmsres.dianzhenkeji.com/files/images/2017/06/23/1498187765828570.png',
      name: '国乐悠扬',
      url: 'http://stream3.hndt.com/now/8bplFuwp/playlist.m3u8'
    },

    {
      logo: 'https://cdn-profiles.tunein.com/s138074/images/logog.png?t=158317',
      name: 'Big B Radio - 中文流行',
      url: 'http://cpop.bigbradio.net/s'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s135096/images/logog.png?t=158317',
      name: 'Big B Radio - 韩语流行',
      url: 'http://kpop.bigbradio.net/s'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s135097/images/logog.png?t=158317',
      name: 'Big B Radio - 日语流行',
      url: 'http://jpop.bigbradio.net/s'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s54185/images/logog.png?t=158645',
      name: 'Big B Radio - 亚洲流行',
      url: 'http://apop.bigbradio.net/s'
    },
    {
      logo: '',
      name: 'Modern Radio',
      url: 'http://play.redefine.ltd/Stream1/FM1058-1.m3u8'
    },
    {
      logo: '',
      name: '电音电台',
      url: 'http://play.redefine.ltd/Stream3/FM1058-3.m3u8'
    },
    {
      logo: '',
      name: '古典音乐台',
      url: 'http://play.redefine.ltd/Stream2/FM1058-2.m3u8'
    },
    {
      logo: 'https://pic.qtfm.cn/2013/channel_logo/4998.jpg',
      name: '萤火虫网络台',
      url: 'http://211.149.226.193:8066/1022.mp3'
    },
    {
      logo: 'https://cdn-web-us-west-2-tunein-com.s3-us-west-2.amazonaws.com/assets/img/shared/tiles/todays-hits-small.jpg',
      name: 'Today’s Hits',
      url: 'http://rfcmedia.streamguys1.com/MusicPulsePremium.mp3'
    },
    {
      logo: 'https://gitee.com/radioer/radioer/raw/main/newpophits.png',
      name: 'Pop Hits',
      url: 'http://rfcmedia.streamguys1.com/newpophitspremium.mp3'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s249914/images/logod.png?t=1',
      name: 'Alternative Hits',
      url: 'http://rfcmedia.streamguys1.com/alternativehitspremium.mp3'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s249981/images/logog.jpg?t=2',
      name: 'Country Hits',
      url: 'http://rfcmedia.streamguys1.com/countryhitspremium.mp3'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s224628/images/logog.jpg?t=4',
      name: 'Country Roads',
      url: 'http://rfcmedia.streamguys1.com/countryroadspremium.mp3'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s249937/images/logog.jpg?t=2',
      name: 'Relax & Unwind',
      url: 'http://rfcmedia.streamguys1.com/relaxationpremium.mp3'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s249973/images/logog.jpg?t=162282',
      name: 'Smooth Jazz',
      url: 'http://rfcmedia.streamguys1.com/smoothjazzpremium.mp3'
    },
    {
      logo: 'https://cdn-radiotime-logos.tunein.com/s291088g.png',
      name: 'The Hit List',
      url: 'http://rfcmedia.streamguys1.com/srhitlist.mp3'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s303095/images/logod.png?t=158589',
      name: 'Dance One',
      url: 'http://rfcmedia.streamguys1.com/srdanceone.mp3'
    },
    {
      logo: 'http://cdn-profiles.tunein.com/g385/images/logod.png?t=636564760576470000',
      name: 'Disco Beats',
      url: 'http://rfcmedia.streamguys1.com/srdiscobeats.mp3'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s249981/images/logog.jpg?t=2',
      name: 'Today Country Hits',
      url: 'http://rfcmedia.streamguys1.com/srtodayscountryhits.mp3'
    },
    {
      logo: 'https://cdn-radiotime-logos.tunein.com/s293704g.png',
      name: 'Coffee House ☕️',
      url: 'http://rfcmedia.streamguys1.com/coffeehousepremium.mp3'
    },
    {
      logo: 'https://gitee.com/radioer/transparentlogo/raw/master/internetradio/tedtalks.png',
      name: 'TED Talks',
      url: 'http://tunein.streamguys1.com/TEDTalks'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s225116/images/logoq.jpg',
      name: 'Star Talk Radio',
      url: 'http://tunein.streamguys1.com/StarTalkRadio'
    },
    {
      logo: 'https://cdn-profiles.tunein.com/s189055/images/logod.png?t=2',
      name: 'Progressive Voices',
      url: 'https://tunein.streamguys1.com/progressivevoices'
    },
    {
      logo: 'https://cdn-radiotime-logos.tunein.com/s96692d.png',
      name: 'Panda Show Radio',
      url: 'https://tunein.streamguys1.com/pandashowradio'
    }

  ]
}
