
export default {
  name: '数字',
  list: [
    {
      logo: 'https://gitee.com/radioer/photo/raw/main/CHC高清电影.png',
      name: 'CHC动作电影',
      url: 'http://39.134.19.228:6610/yinhe/2/ch00000090990000002055/index.m3u8?virtualDomain=yinhe.live_hls.zte.com'
    },
    {
      logo: 'https://gitee.com/radioer/photo/raw/main/CHC高清电影.png',
      name: 'CHC高清电影',
      url: 'http://39.134.18.68/dbiptv.sn.chinamobile.com/PLTV/88888890/224/3221226463/index.m3u8'
    },
    {
      logo: 'https://gitee.com/radioer/photo/raw/main/CHC高清电影.png',
      name: 'CHC家庭影院',
      url: 'http://39.134.18.68/dbiptv.sn.chinamobile.com/PLTV/88888888/224/3221226462/1.m3u8'
    },
    {
      logo: '',
      name: '4K修复',
      url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221228141/index.m3u8'
    },
    {
      logo: '',
      name: '爱上4K',
      url: 'http://39.134.18.66/dbiptv.sn.chinamobile.com/PLTV/88888890/224/3221226352/index.m3u8'
    },
    {
      logo: '',
      name: '4K花园',
      url: 'http://223.110.243.148/ott.js.chinamobile.com/PLTV/3/224/3221227813/index.m3u8'
    },
    {
      logo: '',
      name: '大众影院',
      url: 'http://223.110.243.157/ott.js.chinamobile.com/PLTV/3/224/3221227579/index.m3u8'
    },
    {
      logo: '',
      name: 'IPTV4K',
      url: 'http://124.232.231.246:6610/000000001001/201500000218/index.m3u8?IASHttpSessionId=SLB2046220190906022827233263&m3u8_level=2'
    },
    {
      logo: '',
      name: 'IPTV高清院线',
      url: 'http://58.20.64.92:9999/tsfile/live/1033_1.m3u8'
    },
    {
      logo: '',
      name: 'IPTV古装剧场',
      url: 'http://58.20.64.92:9999/tsfile/live/1044_1.m3u8'
    },
    {
      logo: '',
      name: '北京IPTV 4K超清',
      url: 'http://otttv.bj.chinamobile.com/PLTV/88888888/224/3221226550/index.m3u8?accountinfo=3QGHZfaujB8rRCsAsCvwpxxlHr4QY7xmzJA0CWZ2m2oFrdR+0WqiDAHoOrKmOBjJo1DAVyGMxV75D0k9A8UW52/rO/0g1FiOsgmHLskIhGej57NNTIcVyR/C8/qFakBO3+2/Lci77HWE/MDUdCEgEA==,,END'
    },
    {
      logo: '',
      name: '北京IPTV 大健康',
      url: 'http://otttv.bj.chinamobile.com/PLTV/88888888/224/3221226556/index.m3u8?accountinfo=OMDVq6b4lEslJm1UnEjrD67bT+dBi/stmegbNVdYPJSeBmMWSNPD7Wrrjb2ZDi7Um5UxWiZupPnRfM4wLeS74RrZe+RE99EyWRk+/h2ZwKdrA6O5TqC3oQgciLHCN+f7jdqT/Yq9bJ5ojBI5xjj1XA==,,END'
    },
    {
      logo: '',
      name: '北京IPTV 淘BABY',
      url: 'http://111.13.42.12/PLTV/88888888/224/3221226554/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/quanjishi.png',
      name: 'SiTV全纪实',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221226214/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: '',
      name: 'SiTV法治天地',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221226199/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: 'https://gitee.com/radioer/photo/raw/main/都市剧场.png',
      name: 'SiTV都市剧场',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221226243/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/huanxiao.png',
      name: 'SiTV欢笑剧场4K',
      url: 'http://live.aikan.miguvideo.com/PLTV/88888888/224/3221230739/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/huanxiao.png',
      name: 'SiTV欢笑剧场',
      url: 'http://39.136.18.83/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221226210/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: '',
      name: 'SiTV动漫秀场',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221226211/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/shenghuoshishang.png',
      name: 'SiTV生活时尚',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221226207/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/jinbaotiyu.jpg',
      name: 'SiTV劲爆体育',
      url: 'http://183.207.255.188/live/program/live/jbtyhd/4000000/mnf.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/xinshijue.png',
      name: 'SiTV新视觉',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221226237/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/meilizuqiu.png',
      name: 'SiTV魅力足球',
      url: 'http://183.207.255.188/live/program/live/mlyyhd/2300000/mnf.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/xingfucai.png',
      name: 'SiTV幸福彩',
      url: 'http://183.207.255.188/live/program/live/xfchd/2300000/mnf.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/jisuqiche.png',
      name: 'SiTV极速汽车',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221226249/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: '',
      name: 'SiTV游戏风云',
      url: 'http://39.134.39.5/TVOD/88888888/224/3221226187/index.m3u8'
    },

    {
      logo: '',
      name: 'BesTV超级体育',
      url: 'http://183.207.255.188/live/program/live/hdnba5/4000000/mnf.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/DOXTV.jpg',
      name: 'DoxTV',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221226048/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: '',
      name: 'Y+',
      url: 'http://live.aikan.miguvideo.com/PLTV/88888888/224/3221230226/index.m3u8'
    },
    {
      logo: 'http://www.weathertv.cn/m/i/documentary/logo.png',
      name: '中国天气',
      url: 'http://124.232.231.246:6610/000000001001/201500000158/index.m3u8?IASHttpSessionId=SLB2046220190906022827233263&m3u8_level=2'
    },
    {
      logo: 'https://cctbn.com/images/cctbn_hn.png',
      name: '中国交通频道(海南)',
      url: 'https://tv.lanjingfm.com/cctbn/hainan.m3u8'
    },
    {
      logo: 'https://cctbn.com/images/cctbn_hn.png',
      name: '中国交通频道(湖北)',
      url: 'https://tv.lanjingfm.com/cctbn/hubei.m3u8'
    },
    {
      logo: 'https://cctbn.com/images/cctbn_hn.png',
      name: '中国交通频道(广东)',
      url: 'https://tv.lanjingfm.com/cctbn/guangdong.m3u8'
    },
    {
      logo: 'https://cctbn.com/images/cctbn_hn.png',
      name: '中国交通频道(吉林)',
      url: 'https://tv.lanjingfm.com/cctbn/jilin.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/WENWUBAOKU.jpg',
      name: '文物宝库',
      url: 'http://149.129.100.78:88/dxxw.php?id=155'
    }]
}
