
export default {
  name: '地方',
  list: [
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/diyicaijing.png',
      name: '第一财经',
      url: 'http://39.134.39.5/TVOD/88888888/224/3221226172/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/shanghaidongfangyingshi.png',
      name: '东方影视',
      url: 'http://183.207.255.188/live/program/live/dsjpdhd/4000000/mnf.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/shangshixinwen.png',
      name: '上海新闻综合',
      url: 'http://183.207.255.188/live/program/live/xwzhhd/4000000/mnf.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/shanghaidushi.jpg',
      name: '上海都市',
      url: 'http://117.148.179.165/PLTV/88888888/224/3221231618/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/wuxingtiyu.png',
      name: '五星体育',
      url: 'http://183.207.255.188/live/program/live/ssty/4000000/mnf.m3u8'
    },
    {
      logo: '',
      name: '上海外语',
      url: 'http://39.134.39.5/TVOD/88888888/224/3221226201/index.m3u8'
    },
    {
      logo: '',
      name: '上海教育电视台',
      url: 'http://live2018.setv.sh.cn/setv/programme10_lud.m3u8?auth_key=4790122411-0-0-f6c20db506e028f2f4d4a546c3f9631b'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/BTV新闻.png',
      name: '北京新闻',
      url: 'http://otttv.bj.chinamobile.com/PLTV/88888888/224/3221226437/index.m3u8?accountinfo=OMDVq6b4lEslJm1UnEjrD67bT+dBi/stmegbNVdYPJSeBmMWSNPD7Wrrjb2ZDi7Um5UxWiZupPnRfM4wLeS74RrZe+RE99EyWRk+/h2ZwKe/K/f3kM7vVAVZijl4iyoDNXkzi+WZQiMHs7tmfGVYQg==,,END'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/BTV影视.png',
      name: '北京影视',
      url: 'http://111.13.42.12/PLTV/88888888/224/3221226560/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/BTV文艺.png',
      name: '北京文艺',
      url: 'http://otttv.bj.chinamobile.com/PLTV/88888888/224/3221226440/index.m3u8?accountinfo=OMDVq6b4lEslJm1UnEjrD67bT+dBi/stmegbNVdYPJSeBmMWSNPD7Wrrjb2ZDi7Um5UxWiZupPnRfM4wLeS74RrZe+RE99EyWRk+/h2ZwKcvHqOmtn/hgQoKLE9+7Ay2N6D1ko3Yx6/syfV3AyuDrw==,,END'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/BTV财经.png',
      name: '北京财经',
      url: 'http://otttv.bj.chinamobile.com/PLTV/88888888/224/3221226516/index.m3u8?accountinfo=OMDVq6b4lEslJm1UnEjrD67bT+dBi/stmegbNVdYPJSeBmMWSNPD7Wrrjb2ZDi7Um5UxWiZupPnRfM4wLeS74RrZe+RE99EyWRk+/h2ZwKfGHYsvBC/sDKyAwHpyTnXXArigmPWJ7SmGOzFeuUmOIw==,,END'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/BTV生活.png',
      name: '北京生活',
      url: 'http://otttv.bj.chinamobile.com/PLTV/88888888/224/3221226514/index.m3u8?accountinfo=OMDVq6b4lEslJm1UnEjrD67bT+dBi/stmegbNVdYPJSeBmMWSNPD7Wrrjb2ZDi7Um5UxWiZupPnRfM4wLeS74RrZe+RE99EyWRk+/h2ZwKevbKlK+Xph+b1r8gRT8xH6rfvTyy+7HLCUsK6ruw47JA==,,END'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/BTV青年.png',
      name: '北京青年',
      url: 'http://111.13.42.10/PLTV/88888888/224/3221226562/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/BTV科教.png',
      name: '北京科教',
      url: 'http://111.13.42.43/PLTV/88888888/224/3221226559/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/BTV国际.png',
      name: '北京国际',
      url: 'http://otttv.bj.chinamobile.com/PLTV/88888888/224/3221226510/index.m3u8?accountinfo=OMDVq6b4lEslJm1UnEjrD67bT+dBi/stmegbNVdYPJSeBmMWSNPD7Wrrjb2ZDi7Um5UxWiZupPnRfM4wLeS74RrZe+RE99EyWRk+/h2ZwKe1xeNgD5Kl8Atu9oWmG1uEVrhyTXIuFRsiICwHJAHtyw==,,END'
    },
    {
      logo: '',
      name: '湖南电影',
      url: 'http://149.129.100.78:88/xsj.php?id=hndy'
    },
    {
      logo: '',
      name: '湖南经视',
      url: 'http://149.129.100.78:88/xsj.php?id=hnjs'
    },
    {
      logo: '',
      name: '湖南公共',
      url: 'http://149.129.100.78:88/xsj.php?id=hngg'
    },
    {
      logo: '',
      name: '湖南娱乐',
      url: 'http://149.129.100.78:88/xsj.php?id=hnyl'
    },
    {
      logo: '',
      name: '湖南电视剧',
      url: 'http://149.129.100.78:88/xsj.php?id=hndsj'
    },
    {
      logo: '',
      name: '湖南都市',
      url: 'http://149.129.100.78:88/xsj.php?id=hnds'
    },
    {
      logo: '',
      name: '湖南国际',
      url: 'http://149.129.100.78:88/hunan.php?id=229'
    },
    {
      logo: '',
      name: '湖南教育',
      url: 'http://149.129.100.78:88/xsj.php?id=hnjy'
    },
    {
      logo: 'https://static-pro.guangdianyun.tv/1648/program/20180503/788f5c95682c2bdd3a4945cc018b5067.png',
      name: '长沙政法',
      url: 'http://149.129.100.78:88/xsj.php?id=cszf'
    },
    {
      logo: 'https://static-pro.guangdianyun.tv/1648/program/20200109/f5078b7d3a8f020b8d6f6fcdcd938f1d.jpeg',
      name: '长沙影视',
      url: 'http://149.129.100.78:88/xsj.php?id=csys'
    },
    {
      logo: 'https://static-pro.guangdianyun.tv/1648/program/20180503/788f5c95682c2bdd3a4945cc018b5067.png',
      name: '长沙新闻',
      url: 'http://149.129.100.78:88/xsj.php?id=csxw'
    },
    {
      logo: 'https://static-pro.guangdianyun.tv/1648/program/20180503/788f5c95682c2bdd3a4945cc018b5067.png',
      name: '长沙女性',
      url: 'http://149.129.100.78:88/xsj.php?id=csnx'
    },
    {
      logo: '',
      name: '晋中综合',
      url: 'http://jzlive.jztvnews.com:90/live/jzzh.m3u8'
    },
    {
      logo: '',
      name: '晋中公共',
      url: 'http://jzlive.jztvnews.com:90/live/jzgg.m3u8'
    },
    {
      logo: '',
      name: '榆社电视台',
      url: 'https://hb2stream1.baoxinwen.com/2527ebd712334a70/6616141643588305925_1600914820.m3u8?auth_key=1893456000-0-0-700f7561f8b4f86818c5d7bae4afd140'
    },
    {
      logo: 'https://apphhplushttps.sxrtv.com/images/2020/12/31/202012311609402077716_90.jpg',
      name: '山西社会与法治',
      url: 'http://149.129.100.78:88/cutv.php?id=sxkj'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/huanghe.png',
      name: '黄河电视台',
      url: 'http://149.129.100.78:88/cutv.php?id=sxhh'
    },
    {
      logo: 'https://apphhplushttps.sxrtv.com/images/2020/12/31/202012311609402311324_90.jpg',
      name: '山西经济与科技',
      url: 'http://149.129.100.78:88/cutv.php?id=sxjj'
    },
    {
      logo: 'http://app.sxrtv.com/images/2019/5/15/20195151557897361061_33_t1080.jpg',
      name: '山西影视',
      url: 'http://149.129.100.78:88/cutv.php?id=sxys'
    },
    {
      logo: 'http://app.sxrtv.com/images/2019/5/15/20195151557897422066_33_t1080.jpg',
      name: '山西公共',
      url: 'http://149.129.100.78:88/cutv.php?id=sxgg'
    },
    {
      logo: '',
      name: '福建新闻',
      url: 'rtsp://183.252.166.199/PLTV/88888888/224/3221226116/10000100000000060000000002358068_0.smil'
    },
    {
      logo: '',
      name: '福建电视剧',
      url: 'rtsp://183.252.176.54:554/PLTV/88888888/224/3221226115/10000100000000060000000002358067_0.smil'
    },
    {
      logo: '',
      name: '福建综合',
      url: 'rtsp://183.252.166.199/PLTV/88888888/224/3221226826/47882539.smil'
    },
    {
      logo: '',
      name: '福建经济',
      url: 'rtsp://183.252.166.199/PLTV/88888888/224/3221226117/10000100000000060000000002358072_0.smil'
    },
    {
      logo: '',
      name: '福建旅游',
      url: 'rtsp://183.252.166.199/PLTV/88888888/224/3221226125/10000100000000060000000002358191_0.smil'
    },
    {
      logo: '',
      name: '福建少儿',
      url: 'rtsp://183.252.166.199/PLTV/88888888/224/3221226120/10000100000000060000000002358082_0.smil'
    },
    {
      logo: '',
      name: '福建体育',
      url: 'rtsp://183.252.166.199/PLTV/88888888/224/3221226127/10000100000000060000000002434540_0.smil'
    },
    {
      logo: '',
      name: '福建乡村振兴公共',
      url: 'rtsp://183.252.166.199/PLTV/88888888/224/3221226113/10000100000000060000000002358064_0.smil'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/sctv4.png',
      name: '四川新闻',
      url: 'http://scgctvshow.sctv.com/hdlive/sctv4/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/sctv2.png',
      name: '四川经济',
      url: 'http://scgctvshow.sctv.com/hdlive/sctv2/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/sctv3.png',
      name: '四川文化旅游',
      url: 'http://scgctvshow.sctv.com/hdlive/sctv3/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/sctv5.png',
      name: '四川影视文艺',
      url: 'http://scgctvshow.sctv.com/hdlive/sctv5/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/sctv7.png',
      name: '四川妇女儿童',
      url: 'http://scgctvshow.sctv.com/hdlive/sctv7/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/sctv9.png',
      name: '四川乡村',
      url: 'http://scgctvshow.sctv.com/hdlive/sctv9/1.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/sctv6.png',
      name: '四川星空购物',
      url: 'http://scgctvshow.sctv.com/hdlive/sctv6/1.m3u8'
    },
    {
      logo: '',
      name: '陕西新闻资讯',
      url: 'http://129.226.107.225/zycfcdn.gdwlcloud.com/PLTV/88888888/224/3221225500/index.m3u8'
    },
    {
      logo: '',
      name: '陕西都市青春',
      url: 'http://129.226.107.225/zycfcdn.gdwlcloud.com/PLTV/88888888/224/3221225830/index.m3u8'
    },
    {
      logo: 'https://ohudong.cztv.com/1/logos/2020/12/31/cc2b0ca1bd0db74f75ad810587c28c32.png',
      name: '浙江新闻',
      url: 'http://hw-m-l.cztv.com/channels/lantian/channel07/1080p.m3u8'
    },
    {
      logo: 'https://ohudong.cztv.com/1/logos/2017/04/12/72d035c03412d7cb2d319c6607fef8e9.png',
      name: '浙江国际',
      url: 'http://hw-m-l.cztv.com/channels/lantian/channel010/1080p.m3u8'
    },
    {
      logo: 'https://ohudong.cztv.com/1/logos/2017/04/12/33b59c051574c604f93157baa61455b2.png',
      name: '浙江少儿',
      url: 'http://hw-m-l.cztv.com/channels/lantian/channel008/1080p.m3u8'
    },
    {
      logo: 'https://ohudong.cztv.com/1/logos/2017/04/12/b500dac5d5af1dae3a3eceaf2e165bd0.png',
      name: '浙江教科影视',
      url: 'http://hw-m-l.cztv.com/channels/lantian/channel004/1080p.m3u8'
    },
    {
      logo: 'https://ohudong.cztv.com/1/logos/2017/05/16/090bdb9f3da9df74a275a785c3ba023f.png',
      name: '浙江数码时代',
      url: 'http://hw-m-l.cztv.com/channels/lantian/channel012/1080p.m3u8'
    },
    {
      logo: 'https://ohudong.cztv.com/1/logos/2017/04/11/b11dda9a789c67cb16e6a5a5fce63125.png',
      name: '浙江民生休闲',
      url: 'http://hw-m-l.cztv.com/channels/lantian/channel006/1080p.m3u8'
    },
    {
      logo: 'https://ohudong.cztv.com/1/logos/2017/04/12/6c2c5375eff2669abfc9ad58b5fb200b.png',
      name: '浙江经济生活',
      url: 'http://hw-m-l.cztv.com/channels/lantian/channel003/1080p.m3u8'
    },
    {
      logo: 'https://ohudong.cztv.com/1/logos/2017/04/12/bf6ad9ce4da1182a3a7ed0e188d8632f.png',
      name: '浙江钱江频道',
      url: 'http://hw-m-l.cztv.com/channels/lantian/channel002/1080p.m3u8'
    },
    {
      logo: '',
      name: '河南都市',
      url: 'http://149.129.100.78:88/dxxw.php?id=141'
    },
    {
      logo: '',
      name: '河南电视剧',
      url: 'http://149.129.100.78:88/dxxw.php?id=148'
    },
    {
      logo: '',
      name: '河南民生',
      url: 'http://149.129.100.78:88/dxxw.php?id=146'
    },
    {
      logo: '',
      name: '河南法治',
      url: 'http://149.129.100.78:88/dxxw.php?id=147'
    },
    {
      logo: '',
      name: '河南新闻',
      url: 'http://149.129.100.78:88/dxxw.php?id=149'
    },
    {
      logo: '',
      name: '河南公共',
      url: 'http://149.129.100.78:88/dxxw.php?id=151'
    },
    {
      logo: '',
      name: '河南乡村',
      url: 'http://149.129.100.78:88/dxxw.php?id=152'
    },
    {
      logo: '',
      name: '河南国际',
      url: 'http://149.129.100.78:88/dxxw.php?id=153'
    },
    {
      logo: '',
      name: '河北影视剧',
      url: 'http://live6.plus.hebtv.com/hbysx/hd/live.m3u8'
    },
    {
      logo: '',
      name: '河北经济生活',
      url: 'http://live2.plus.hebtv.com/jjshx/playlist.m3u8'
    },
    {
      logo: '',
      name: '河北农民',
      url: 'http://live3.plus.hebtv.com/nmpdx/playlist.m3u8'
    },
    {
      logo: '',
      name: '河北都市',
      url: 'http://live3.plus.hebtv.com/hbdsx/playlist.m3u8'
    },
    {
      logo: '',
      name: '河北少儿科教',
      url: 'http://live6.plus.hebtv.com/sekjx/playlist.m3u8'
    },
    {
      logo: '',
      name: '河北公共',
      url: 'http://live7.plus.hebtv.com/hbggx/playlist.m3u8'
    },
    {
      logo: '',
      name: '甘肃都市',
      url: 'http://39.134.39.39/PLTV/88888888/224/3221226248/index.m3u8'
    },
    {
      logo: '',
      name: '甘肃公共应急',
      url: 'http://39.134.39.39/PLTV/88888888/224/3221226250/index.m3u8'
    },
    {
      logo: '',
      name: '甘肃经济',
      url: 'http://39.134.39.39/PLTV/88888888/224/3221226252/index.m3u8'
    },
    {
      logo: '',
      name: '甘肃少儿',
      url: 'http://39.134.39.39/PLTV/88888888/224/3221226289/index.m3u8'
    },
    {
      logo: '',
      name: '甘肃文化影视',
      url: 'http://39.134.39.39/PLTV/88888888/224/3221226287/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/yunnan.png',
      name: '云南都市',
      url: 'https://hwapi.yunshicloud.com/62hdvf/rjwt14.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/yunnan.png',
      name: '云南影视',
      url: 'https://hwapi.yunshicloud.com/62hdvf/utrat7.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/yunnan.png',
      name: '云南娱乐',
      url: 'http://tvlive.ynradio.com/live/yunnanyule/playlist.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/yunnan.png',
      name: '云南公共',
      url: 'https://hwapi.yunshicloud.com/62hdvf/227q43.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/yunnan.png',
      name: '云南国际',
      url: 'http://tvlive.ynradio.com/live/yunnanguoji/playlist.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/yunnan.png',
      name: '云南生活资讯',
      url: 'https://hwapi.yunshicloud.com/62hdvf/180p57.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/yunnan.png',
      name: '云南少儿',
      url: 'http://tvlive.ynradio.com/live/yunnanshaoer/playlist.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/yunnan.png',
      name: '澜湄国际',
      url: 'http://tvlive.ynradio.com/live/yunnanguoji/playlist.m3u8'
    },
    {
      logo: '',
      name: '合肥新闻',
      url: 'http://223.244.92.30:808/2774goN/1000/live.m3u8'
    },
    {
      logo: '',
      name: '合肥生活',
      url: 'http://223.244.92.30:808/xcd72q7/1000/live.m3u8'
    },
    {
      logo: '',
      name: '合肥财经',
      url: 'http://223.244.92.30:808/8Fvh9ro/1000/live.m3u8'
    },
    {
      logo: '',
      name: '合肥教育法制',
      url: 'http://223.244.92.30:808/x91Hoz8/1000/live.m3u8'
    },
    {
      logo: '',
      name: '合肥故事休闲',
      url: 'http://223.244.92.30:808/52e58Sh/1000/live.m3u8'
    },
    {
      logo: '',
      name: '合肥文体博览',
      url: 'http://223.244.92.30:808/FgrhqyC/1000/live.m3u8'
    },
    {
      logo: '',
      name: '合肥公共',
      url: 'http://223.244.92.30:808/NlwQhy0/1000/live.m3u8'
    },
    {
      logo: '',
      name: '昆明新闻综合',
      url: 'https://devlivepull.migucloud.com/live/01YCQY7M_C0.flv'
    },
    {
      logo: '',
      name: '昆明公共',
      url: 'https://devlivepull.migucloud.com/live/UD0YLY2G_C0_3.flv'
    },
    {
      logo: '',
      name: '昆明经济生活',
      url: 'https://devlivepull.migucloud.com/live/JT9JEQ54_C0_2.flv'
    },
    {
      logo: '',
      name: '昆明科学教育',
      url: 'https://devlivepull.migucloud.com/live/ZBXWIMTD_C0_2.flv'
    },
    {
      logo: '',
      name: '昆明文体娱乐',
      url: 'https://devlivepull.migucloud.com/live/6KN3ZB2S_C0_2.flv'
    },
    {
      logo: '',
      name: '昆明影视综艺',
      url: 'https://devlivepull.migucloud.com/live/KYLNJWFD_C0_2.flv'
    },
    {
      logo: '',
      name: '南宁新闻综合',
      url: 'http://hls.nntv.cn/nnlive/NNTV_NEWS_A.m3u8'
    },
    {
      logo: '',
      name: '南宁都市生活',
      url: 'http://hls.nntv.cn/nnlive/NNTV_METRO_A.m3u8'
    },
    {
      logo: '',
      name: '南宁影视娱乐',
      url: 'http://hls.nntv.cn/nnlive/NNTV_VOD_A.m3u8'
    },
    {
      logo: '',
      name: '南宁公共',
      url: 'http://hls.nntv.cn/nnlive/NNTV_PUB_A.m3u8'
    },
    {
      logo: '',
      name: '兰州新闻综合',
      url: 'http://39.134.39.39/PLTV/88888888/224/3221226242/index.m3u8'
    },
    {
      logo: '',
      name: '兰州公共',
      url: 'http://39.134.39.39/PLTV/88888888/224/3221226244/index.m3u8'
    },
    {
      logo: '',
      name: '兰州经济生活',
      url: 'http://39.134.39.39/PLTV/88888888/224/3221226285/index.m3u8'
    },
    {
      logo: '',
      name: '兰州综艺体育',
      url: 'http://39.134.39.39/PLTV/88888888/224/3221226246/index.m3u8'
    },
    {
      logo: '',
      name: '睛彩中原',
      url: 'http://149.129.100.78:88/dxxw.php?id=157'
    },
    {
      logo: '',
      name: '移动戏曲',
      url: 'http://149.129.100.78:88/dxxw.php?id=163'
    },
    {
      logo: '',
      name: '苏州新闻综合',
      url: 'rtmp://csztv.2500sz.com/live/c01'
    },
    {
      logo: '',
      name: '苏州社会经济',
      url: 'rtmp://csztv.2500sz.com/live/c02'
    },
    {
      logo: '',
      name: '苏州文化生活',
      url: 'rtmp://csztv.2500sz.com/live/c03'
    },
    {
      logo: '',
      name: '苏州生活资讯',
      url: 'rtmp://csztv.2500sz.com/live/c04'
    },
    {
      logo: '',
      name: '徐州新闻综合',
      url: 'http://stream1.huaihai.tv/xwzh/playlist.m3u8'
    },
    {
      logo: '',
      name: '徐州经济生活',
      url: 'http://stream1.huaihai.tv/jjsh/playlist.m3u8'
    },
    {
      logo: '',
      name: '徐州文艺影视',
      url: 'http://stream1.huaihai.tv/wyys/playlist.m3u8'
    },
    {
      logo: '',
      name: '杭州综合',
      url: 'http://117.148.179.132/PLTV/88888888/224/3221229307/index.m3u8'
    },
    {
      logo: '',
      name: '杭州明珠',
      url: 'http://117.148.179.135/PLTV/88888888/224/3221229310/index.m3u8'
    },
    {
      logo: '',
      name: '杭州生活',
      url: 'http://117.148.179.134/PLTV/88888888/224/3221229313/index.m3u8'
    },
    {
      logo: '',
      name: '杭州影视',
      url: 'http://117.148.179.133/PLTV/88888888/224/3221229377/index.m3u8'
    },
    {
      logo: '',
      name: '杭州青少体育',
      url: 'http://117.148.179.133/PLTV/88888888/224/3221229316/index.m3u8'
    },
    {
      logo: '',
      name: '杭州导视',
      url: 'http://112.15.230.49/PLTV/88888888/224/3221230469/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/HUBEI2.png',
      name: '湖北综合',
      url: 'http://149.129.100.78:88/hubei.php?id=433'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/HUBEI7.png',
      name: '湖北公共新闻',
      url: 'http://149.129.100.78:88/hubei.php?id=434'
    },
    {
      logo: '',
      name: '湖北经视',
      url: 'http://149.129.100.78:88/hubei.php?id=432'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/HUBEI4.png',
      name: '湖北教育',
      url: 'http://149.129.100.78:88/hubei.php?id=437'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/HBLS.png',
      name: '湖北垄上',
      url: 'http://149.129.100.78:88/hubei.php?id=438'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/HUBEI5.png',
      name: '湖北生活',
      url: 'http://149.129.100.78:88/hubei.php?id=436'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/HUBEI3.png',
      name: '湖北影视',
      url: 'http://149.129.100.78:88/hubei.php?id=435'
    },
    {
      logo: '',
      name: '湖北美嘉购物',
      url: 'http://149.129.100.78:88/hubei.php?id=439'
    },
    {
      logo: '',
      name: '深圳都市',
      url: 'http://149.129.100.78:88/sztv.php?id=2'
    },
    {
      logo: '',
      name: '深圳电视剧',
      url: 'http://149.129.100.78:88/sztv.php?id=3'
    },
    {
      logo: '',
      name: '深圳公共',
      url: 'http://149.129.100.78:88/sztv.php?id=4'
    },
    {
      logo: '',
      name: '深圳财经生活',
      url: 'http://149.129.100.78:88/sztv.php?id=5'
    },
    {
      logo: '',
      name: '深圳娱乐',
      url: 'http://149.129.100.78:88/sztv.php?id=6'
    },
    {
      logo: '',
      name: '深圳少儿',
      url: 'http://149.129.100.78:88/sztv.php?id=7'
    },
    {
      logo: '',
      name: '深圳移动电视',
      url: 'http://149.129.100.78:88/sztv.php?id=8'
    },
    {
      logo: '',
      name: '佛山综合',
      url: 'http://pili-live-rtmp.wdit.com.cn/wditlive/fs_zhpd.m3u8'
    },
    {
      logo: '',
      name: '佛山影视',
      url: 'http://pili-live-rtmp.wdit.com.cn/wditlive/fs_yspd.m3u8'
    },
    {
      logo: '',
      name: '佛山南海',
      url: 'http://pili-live-rtmp.wdit.com.cn/wditlive/fs_nhpd.m3u8'
    },
    {
      logo: '',
      name: '佛山顺德',
      url: 'http://pili-live-rtmp.wdit.com.cn/wditlive/fs_sdpd.m3u8'
    },
    {
      logo: '',
      name: '佛山公共',
      url: 'http://pili-live-rtmp.wdit.com.cn/wditlive/fs_ggpd.m3u8'
    },
    {
      logo: '',
      name: '佛山高明',
      url: 'http://pili-live-rtmp.wdit.com.cn/wditlive/fs_gmpd.m3u8'
    },
    {
      logo: '',
      name: '佛山三水',
      url: 'http://pili-live-rtmp.wdit.com.cn/wditlive/fs_sspd.m3u8'
    },
    {
      logo: '',
      name: '雅安新闻综合',
      url: 'https://m3u8channel.wuxianyaan.com/cms/videos/nmip-media/channellive/channel1/playlist.m3u8'
    },
    {
      logo: '',
      name: '雅安公共',
      url: 'https://m3u8channel.wuxianyaan.com/cms/videos/nmip-media/channellive/channel2/playlist.m3u8'
    },
    {
      logo: '',
      name: '雨城频道',
      url: 'https://m3u8channel.wuxianyaan.com/cms/videos/nmip-media/channellive/channel4/playlist.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/cdtv1.png',
      name: '成都新闻综合',
      url: 'http://149.129.100.78:88/cdtv.php?id=1'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/cdtv2.png',
      name: '成都经济',
      url: 'http://149.129.100.78:88/cdtv.php?id=2'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/cdtv3.png',
      name: '成都都市',
      url: 'http://149.129.100.78:88/cdtv.php?id=3'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/cdtv4.png',
      name: '成都影视',
      url: 'http://149.129.100.78:88/cdtv.php?id=45'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/cdtv5.png',
      name: '成都公共',
      url: 'http://149.129.100.78:88/cdtv.php?id=5'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/sheng/cdtv6.png',
      name: '成都少儿',
      url: 'http://149.129.100.78:88/cdtv.php?id=6'
    },
    {
      logo: '',
      name: '黑龙江影视',
      url: 'http://idclive.hljtv.com:8080/live/yingshi_sd.m3u8'
    },
    {
      logo: '',
      name: '黑龙江都市',
      url: 'http://idclive.hljtv.com:8080/live/dushi_hd.m3u8'
    },
    {
      logo: '',
      name: '黑龙江新闻法治',
      url: 'http://idclive.hljtv.com:8080/live/xinwen_sd.m3u8'
    },
    {
      logo: '',
      name: '黑龙江少儿',
      url: 'http://idclive.hljtv.com:8080/live/shaoer_sd.m3u8'
    },
    {
      logo: '',
      name: '黑龙江文体',
      url: 'http://idclive.hljtv.com:8080/live/wenyi_sd.m3u8'
    },
    {
      logo: '',
      name: '黑龙江公共农业',
      url: 'http://idclive.hljtv.com:8080/live/gonggong_sd.m3u8'
    },
    {
      logo: '',
      name: '惠州新闻综合',
      url: 'http://livehuiz.chinamcache.com/live/zb01.m3u8'
    },
    {
      logo: '',
      name: '惠州公共',
      url: 'http://livehuiz.chinamcache.com/live/zb02.m3u8'
    },
    {
      logo: '',
      name: '常州新闻综合',
      url: 'http://223.110.246.80/ott.js.chinamobile.com/PLTV/4/224/3221227899/index.m3u8'
    },
    {
      logo: '',
      name: '常州都市',
      url: 'http://223.110.246.82/ott.js.chinamobile.com/PLTV/4/224/3221227955/index.m3u8'
    },
    {
      logo: '',
      name: '常州生活',
      url: 'http://223.110.246.80/ott.js.chinamobile.com/PLTV/4/224/3221227902/index.m3u8'
    },
    {
      logo: '',
      name: '常州公共',
      url: 'http://223.110.246.82/ott.js.chinamobile.com/PLTV/4/224/3221227905/index.m3u8'
    },
    {
      logo: '',
      name: '江西都市',
      url: 'http://149.129.100.78:88/jxtv.php?id=jxtv2'
    },
    {
      logo: '',
      name: '江西经济生活',
      url: 'http://149.129.100.78:88/jxtv.php?id=jxtv3'
    },
    {
      logo: '',
      name: '江西影视旅游',
      url: 'http://149.129.100.78:88/jxtv.php?id=jxtv4'
    },
    {
      logo: '',
      name: '江西公共农业',
      url: 'http://149.129.100.78:88/jxtv.php?id=jxtv5'
    },
    {
      logo: '',
      name: '江西少儿',
      url: 'http://149.129.100.78:88/jxtv.php?id=jxtv6'
    },
    {
      logo: '',
      name: '江西新闻',
      url: 'http://149.129.100.78:88/jxtv.php?id=jxtv7'
    },
    {
      logo: '',
      name: '江西移动电视',
      url: 'http://149.129.100.78:88/jxtv.php?id=jxtv8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/sd_qilu.png',
      name: '山东齐鲁',
      url: 'http://livealone302.iqilu.com/iqilu/qlpd.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/sd_tiyu.png',
      name: '山东体育',
      url: 'http://livealone302.iqilu.com/iqilu/typd.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/sd_shenghuo.png',
      name: '山东生活',
      url: 'http://livealone302.iqilu.com/iqilu/shpd.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/sd_zongyi.png',
      name: '山东综艺',
      url: 'http://livealone302.iqilu.com/iqilu/zypd.m3u8'
    },
    {
      logo: '',
      name: '山东新闻',
      url: 'http://livealone302.iqilu.com/iqilu/ggpd.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/sd_nongke.png',
      name: '山东农科',
      url: 'http://livealone302.iqilu.com/iqilu/nkpd.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/sd_yingshi.png',
      name: '山东文旅',
      url: 'http://livealone302.iqilu.com/iqilu/yspd.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/sd_shaoer.png',
      name: '山东少儿',
      url: 'http://livealone302.iqilu.com/iqilu/sepd.m3u8'
    },
    {
      logo: '',
      name: '山东居家购物',
      url: 'http://livealone302.iqilu.com/iqilu/gwpd.m3u8'
    },
    {
      logo: '',
      name: '绍兴新闻综合',
      url: 'http://live.shaoxing.com.cn/video/s10001-sxhb/index.m3u8'
    },
    {
      logo: '',
      name: '绍兴公共',
      url: 'http://live.shaoxing.com.cn/video/s10001-sxtv2/index.m3u8'
    },
    {
      logo: '',
      name: '绍兴文化影视',
      url: 'http://live.shaoxing.com.cn/video/s10001-sxtv3/index.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/xatv1.jpg',
      name: '西安新闻综合',
      url: 'http://stream2.xiancity.cn/xatv1/playlist.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/xatv2.jpg',
      name: '西安都市',
      url: 'http://stream2.xiancity.cn/xatv2/playlist.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/xatv3.jpg',
      name: '西安商务资讯',
      url: 'http://stream2.xiancity.cn/xatv3/playlist.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/xatv4.jpg',
      name: '西安影视',
      url: 'http://stream2.xiancity.cn/xatv4/playlist.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/xatv5.jpg',
      name: '西安丝路',
      url: 'http://stream2.xiancity.cn/xatv5/playlist.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/fztv1.jpg',
      name: '福州新闻综合',
      url: 'http://live.zohi.tv/video/s10001-FZTV-1/index.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/fztv2.jpg',
      name: '福州影视',
      url: 'http://live.zohi.tv/video/s10001-yspd-2/index.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/fztv3.jpg',
      name: '福州生活',
      url: 'http://live.zohi.tv/video/s10001-shpd-3/index.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/fztv4.jpg',
      name: '福州少儿',
      url: 'http://live.zohi.tv/video/s10001-sepd-4/index.m3u8'
    },
    {
      logo: '',
      name: '泉州新闻综合',
      url: 'rtmp://live.qztv.cn/live/news'
    },
    {
      logo: '',
      name: '泉州闽南语',
      url: 'rtmp://live.qztv.cn/live/mny'
    },
    {
      logo: 'http://www.ldntv.cn/Portal/Tpl/templates/default/images/live_pic1.png',
      name: '娄底综合',
      url: 'http://mms.ldntv.cn:1935/live/zonghe/playlist.m3u8'
    },
    {
      logo: 'https://gitee.com/radioer/photo/raw/main/厦门-1.png',
      name: '厦门综合',
      url: 'https://xiamencncc.v.wscdns.com/atjtd/yhxmt1_1/index.m3u8'
    },
    {
      logo: 'https://gitee.com/radioer/photo/raw/main/厦门-2.png',
      name: '厦门海峡',
      url: 'https://xiamencncc.v.wscdns.com/atjtd/yhxmt2_1/index.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/xmtv6.jpg',
      name: '厦门移动',
      url: 'https://xiamencncc.v.wscdns.com/atjtd/yhxmtyd_1/index.m3u8'
    },
    {
      logo: 'http://www.dayuntongzhou.com/web/style/images/logo.png',
      name: '通州电视台',
      url: 'http://pull.dayuntongzhou.com/live/tztv.m3u8'
    },
    {
      logo: 'http://www.huairtv.com/Portal/Tpl/templates/default/images/logo.png',
      name: '怀柔电视台',
      url: 'http://live.huairtv.com:1935/dvrLive/hrtvmb/playlist.m3u8'
    },
    {
      logo: 'http://img.funhillrm.com/201912173520409e01fadc9c2811d72ff26f62ef.png',
      name: '房山电视台',
      url: 'https://live.funhillrm.com/2/playlist.m3u8'
    },
    {
      logo: '',
      name: '石景山电视台',
      url: 'http://live.sjsrm.com/bjsjs/sd/live.m3u8'
    },
    {
      logo: 'http://www.hnxttv.com/_CMS_NEWS_IMG_/1/201512/31/2d115c55-302d-4eb1-806d-7ac417875d92.png',
      name: '湘潭公共',
      url: 'http://live.hnxttv.com:9601/live/dspd/800K/tzwj_video.m3u8'
    },
    {
      logo: 'http://www.hnxttv.com/_CMS_NEWS_IMG_/1/201512/31/2d115c55-302d-4eb1-806d-7ac417875d92.png',
      name: '湘潭新闻综合',
      url: 'http://live.hnxttv.com:9601/live/xwzh/800K/tzwj_video.m3u8'
    }
  ]
}
