
export default {
  name: 'HEVC专区',
  list: [{
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV1.png',
    name: 'CCTV-1 综合 5.1CH',
    url: 'http://39.135.140.104:6610/PLTV/88888888/224/3221226221/2/index.m3u8?fmt=ts2hls'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV2.png',
    name: 'CCTV-2 财经 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227708/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV3.png',
    name: 'CCTV-3 综艺 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227650/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV4.png',
    name: 'CCTV-4 中文国际 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227658/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV5.png',
    name: 'CCTV-5 体育 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227661/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV5+.png',
    name: 'CCTV-5+ 体育赛事 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227714/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV6.png',
    name: 'CCTV-6 电影',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227664/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV7.png',
    name: 'CCTV-7 国防军事 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227642/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV8.png',
    name: 'CCTV-8 电视剧 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227667/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV9.png',
    name: 'CCTV-9 记录 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227659/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV10.png',
    name: 'CCTV-10 科教 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227717/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV11.png',
    name: 'CCTV-11 戏曲 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227720/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV12.png',
    name: 'CCTV-12 社会与法 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227655/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV13.png',
    name: 'CCTV-13 新闻 5.1CH',
    url: 'http://39.134.135.84/otttv.bj.chinamobile.com/TVOD/88888888/224/3221226316/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV14.png',
    name: 'CCTV-14 少儿 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227656/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV15.png',
    name: 'CCTV-15 音乐 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227723/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/CCTV/CCTV17.png',
    name: 'CCTV-17 农业农村 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227726/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/hunan.png',
    name: '湖南卫视 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227735/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/zhejiang.png',
    name: '浙江卫视 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227744/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/jiangsu.png',
    name: '江苏卫视 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227668/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/dongfang.png',
    name: '东方卫视 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227732/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/beijing.png',
    name: '北京卫视 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227652/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/beijingjishi.png',
    name: '北京冬奥纪实 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227711/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/shanghaijishi.png',
    name: '纪实人文 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227674/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/guangdong.png',
    name: '广东卫视 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227673/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/chongqing.png',
    name: '重庆卫视 5.1CH',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227729/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/shenzhen.png',
    name: '深圳卫视',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227695/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/shandong.png',
    name: '山东卫视',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227671/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/anhui.png',
    name: '安徽卫视',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227653/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/dongnan.png',
    name: '东南卫视',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227670/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/heilongjiang.png',
    name: '黑龙江卫视',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227662/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/liaoning.png',
    name: '辽宁卫视',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227676/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/tianjin.png',
    name: '天津卫视',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227756/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/hubei.png',
    name: '湖北卫视',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227665/index.m3u8'
  },
  {
    logo: 'http://epg.51zmt.top:8000/tb1/ws/jiangxi.png',
    name: '江西卫视',
    url: 'http://ott.js.chinamobile.com/PLTV/3/224/3221227738/index.m3u8'
  }
  ]
}
