
export default {
  name: '港澳台',
  list: [
    // {
    //   logo: '',
    //   name: '测试',
    //   url: 'http://103.140.229.13/zbtv/zxxg.php?id=1&xl=1'
    // },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/fenghuangzixun.png',
      name: '凤凰卫视资讯台',
      url: 'https://zb.ios.ifeng.com/live/05QGCOB3T34/index.m3u8'
    },
    {
      logo: 'http://c1.fengshows-cdn.com/a/2021_22/ede3d9e09be28e5.png',
      name: '凤凰卫视中文台',
      url: 'http://zb.ios.ifeng.com/live/05QGDA0CIRK/index.m3u8'
    },
    {
      logo: '',
      name: '香港C+',
      url: 'http://gslbmeiah.cdn.hk.chinamobile.com/livehls/MOB-U1-NO/03.m3u8'
    },
    {
      logo: 'https://television-live.com/uploads/posts/2019-06/1561572071_lotus-tv-macau.png',
      name: '莲花卫视',
      url: 'http://149.129.100.78:88/lotus.php?id=1'
    },
    {
      logo: 'https://www.hkdtmb.com/png_logo/81.png',
      name: '翡翠台',
      url: 'https://cdn.hkdtmb.com/hls/81/index.m3u8'
    },
    {
      logo: 'https://www.hkdtmb.com/png_logo/84.png',
      name: '明珠台',
      url: 'https://cdn.hkdtmb.com/hls/84/index.m3u8'
    },
    {
      logo: 'https://www.hkdtmb.com/png_logo/83.png',
      name: '无线新闻台',
      url: 'https://cdn.hkdtmb.com/hls/83/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/youxianxinwen.png',
      name: '有线新闻台',
      url: 'https://api.leonardpark.dev/live/icable/109'
    },
    {
      logo: 'https://www.hkdtmb.com/png_logo/85.png',
      name: '无线财经资讯台',
      url: 'https://cdn.hkdtmb.com/hls/85/index.m3u8'
    },
    {
      logo: 'https://www.hkdtmb.com/png_logo/76.png',
      name: '香港国际财经台',
      url: 'https://cdn.hkdtmb.com/hls/76/index.m3u8'
    },
    {
      logo: 'https://www.hkdtmb.com/png_logo/82.png',
      name: 'J2',
      url: 'https://cdn.hkdtmb.com/hls/82/index.m3u8'
    },
    {
      logo: 'https://www.hkdtmb.com/png_logo/99.png',
      name: 'ViU TV',
      url: 'https://cdn.hkdtmb.com/hls/99/index.m3u8'
    },
    {
      logo: 'https://www.hkdtmb.com/png_logo/96.png',
      name: 'ViU 6',
      url: 'https://cdn.hkdtmb.com/hls/96/index.m3u8'
    },
    {
      logo: 'https://www.hkdtmb.com/png_logo/77.png',
      name: '香港开电视',
      url: 'https://cdn.hkdtmb.com/hls/77/index.m3u8'
    },
    {
      logo: 'https://gitee.com/radioer/radioer/raw/main/RTHK31.png',
      name: 'RTHK31',
      url: 'https://cdn.hkdtmb.com/hls/31/index.m3u8'
    },
    {
      logo: 'https://gitee.com/radioer/radioer/raw/main/RTHK31.png',
      name: 'RTHK32',
      url: 'https://cdn.hkdtmb.com/hls/32/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/FTVNews.png',
      name: '民视新闻台',
      url: 'http://v3948069e.live.126.net/live/cmcfizof46736198/36.flv'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/NextTVNews.png',
      name: '壹新闻',
      url: 'http://pullhls3948069e.live.126.net/live/xtxndaws57833801/50/playlist.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/EBCNews.png',
      name: '东森新闻',
      url: 'http://v3948069e.live.126.net/live/chljmwfc26030164/42.flv'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/TVBS新闻.png',
      name: 'TVBS新闻',
      url: 'http://v3948069e.live.126.net/live/eyisbshx78425035/46.flv'
    },
    {
      logo: '',
      name: '中天新闻',
      url: 'http://v3948069e.live.126.net/live/myewtdtq55241913/40.flv'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/elta_sports2.png',
      name: 'ELEVEN2',
      url: 'rtmp://59.124.75.138:1935/sat/md021'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/东风卫视.png',
      name: '东风卫视',
      url: 'http://sytv.xtvants.fun/mytv.php?id=dfws&token=free&user=free'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/民视.png',
      name: '民视',
      url: 'rtmp://59.124.75.138/sat/tv051'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/台视.png',
      name: '台视',
      url: 'rtmp://59.124.75.138/sat/tv071'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/中视.png',
      name: '中视',
      url: 'rtmp://59.124.75.138/sat/tv091'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/CTS.png',
      name: '华视',
      url: 'rtmp://59.124.75.138:1935/sat/tv111'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/PublicTV.png',
      name: '公视',
      url: 'http://sytv.xtvants.fun/mytv.php?id=gs&token=free&user=free'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/AXN.png',
      name: 'AXN',
      url: 'http://v3948069e.live.126.net/live/lgdhdhqt88784905/85.flv'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/TVBS欢乐.png',
      name: 'TVBS欢乐',
      url: 'rtmp://59.124.75.138/sat/tv421'
    },
    {
      logo: 'https://upload.wikimedia.org/wikipedia/zh/thumb/d/d6/MASTV.png/180px-MASTV.png',
      name: '澳亚卫视',
      url: 'http://live.mastvnet.com:800/4rlff1m/live.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/gt/hks.png',
      name: '香港卫视',
      url: 'http://zhibo.hkstv.tv/livestream/mutfysrq/playlist.m3u8'
    },
    {
      logo: '',
      name: '亚美卫视',
      url: 'http://dcunilive30-lh.akamaihd.net/i/dclive_1@535522/master.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/tdmzxt.jpg',
      name: '澳门资讯',
      url: 'http://live3.tdm.com.mo:1935/ch5/info_ch5.live/playlist.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/aomen-macau.jpg',
      name: '澳门卫星',
      url: 'http://live3.tdm.com.mo:1935/ch3/ch3.live/playlist.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/tdmgq.jpg',
      name: '澳门综艺',
      url: 'http://live2.tdm.com.mo:1935/ch6/hd_ch6.live/playlist.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/tdmty.jpg',
      name: '澳门体育',
      url: 'http://live3.tdm.com.mo:1935/ch4/sport_ch4.live/playlist.m3u8'
    },
    {
      logo: 'http://www.tvyan.com/uploads/dianshi/tdmaomen.jpg',
      name: '澳视澳门',
      url: 'http://live3.tdm.com.mo:1935/ch1/ch1.live/playlist.m3u8'
    },
    {
      logo: 'https://ss0.baidu.com/-Po3dSag_xI4khGko9WTAnF6hhy/baike/pic/item/4034970a304e251f46b43584a786c9177f3e530f.jpg',
      name: '美国中文电视',
      url: 'https://jpts.sinovision.net/livestream.m3u8'
    }]
}
