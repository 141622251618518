
export default {
  name: '卫视',
  list: [
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/dongfang.png',
      name: '东方卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225682/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/beijing.png',
      name: '北京卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225651/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/tianjin.png',
      name: '天津卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225675/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/hunan.png',
      name: '湖南卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225692/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/zhejiang.png',
      name: '浙江卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225695/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },

    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/jiangsu.png',
      name: '江苏卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225636/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },

    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/shenzhen.png',
      name: '深圳卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225691/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/guangdong.png',
      name: '广东卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225666/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/anhui.png',
      name: '安徽卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225671/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/sichuan.png',
      name: '四川卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225757/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/jilin.png',
      name: '吉林卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225678/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/dongnan.png',
      name: '东南卫视',
      url: 'http://39.130.202.114:6610/gitv_live/G_DONGNAN-HD/G_DONGNAN-HD.m3u8'
    },

    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/chongqing.png',
      name: '重庆卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225679/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/hubei.png',
      name: '湖北卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225689/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/shandong.png',
      name: '山东卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225642/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/yunnan.png',
      name: '云南卫视',
      url: 'http://39.130.202.114:6610/gitv_live/G_YUNNAN-HD/G_YUNNAN-HD.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/guangxi.png',
      name: '广西卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225767/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/jiangxi.png',
      name: '江西卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225669/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/liaoning.png',
      name: '辽宁卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225639/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/heilongjiang.png',
      name: '黑龙江卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225686/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/hebei.png',
      name: '河北卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225773/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/lvyou.png',
      name: '海南卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225752/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/qinghai.png',
      name: '青海卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225705/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/guizhou.png',
      name: '贵州卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225770/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/shanxi_.png',
      name: '山西卫视',
      url: 'http://111.12.101.23:6610/PLTV/77777777/224/3221225785/index.m3u8?servicetype=1&IASHttpSessionId=OTT'
    },
    {
      logo: '',
      name: '西藏卫视',
      url: 'http://39.135.129.165/hwottcdn.ln.chinamobile.com/PLTV/-/224/3221226896/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/gansu.png',
      name: '甘肃卫视',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221225755/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/xinjiang.png',
      name: '新疆卫视',
      url: 'http://111.0.27.21/livehyw5.chinamcache.com/hyw/zb01.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/xiamen.png',
      name: '厦门卫视',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221225761/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: '',
      name: '延边卫视',
      url: 'http://39.135.129.163/hwottcdn.ln.chinamobile.com/PLTV/-/224/3221227047/index.m3u8'
    },
    {
      logo: '',
      name: '海峡卫视',
      url: 'rtsp://183.252.166.199/PLTV/88888888/224/3221226128/10000100000000060000000002434539_0.smil'
    },
    {
      logo: 'http://ikanpic.kanketv.com/image/live/icon/bingtuan.png',
      name: '兵团卫视',
      url: 'http://test5.btzx.com.cn/live/btxjbtws.stream/playlist.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/beijingjishi.png',
      name: '北京冬奥纪实',
      url: 'http://129.226.107.225/zycfcdn.gdwlcloud.com/PLTV/88888888/224/3221226011/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/shanghaijishi.png',
      name: '纪实人文',
      url: 'http://39.135.138.34:18890/PLTV/88888910/224/3221225655/index.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/ws/jinyingjishi.png',
      name: '金鹰纪实',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221225671/1/index.m3u8?fmt=ts2hls'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/jinyingkatong.png',
      name: '金鹰卡通',
      url: 'http://124.232.231.246:6610/000000001001/201500000154/index.m3u8?IASHttpSessionId=SLB2046220190906022827233263&m3u8_level=2'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/kaku.png',
      name: '卡酷少儿',
      url: 'http://otttv.bj.chinamobile.com/PLTV/88888888/224/3221226558/index.m3u8?accountinfo=OMDVq6b4lEslJm1UnEjrD67bT+dBi/stmegbNVdYPJSeBmMWSNPD7Wrrjb2ZDi7Um5UxWiZupPnRfM4wLeS74RrZe+RE99EyWRk+/h2ZwKcZU6zpMnBRj8F5x1e65FnY33yNhKtBp1tUOIOoQaZW2g==,,END'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/xuandong.png',
      name: '哈哈炫动',
      url: 'http://183.207.255.188/live/program/live/hhxdhd/4000000/mnf.m3u8'
    },
    {
      logo: 'http://epg.51zmt.top:8000/tb1/qt/jiajiakt.png',
      name: '嘉佳卡通',
      url: 'http://39.136.18.76/cdnrrs.gz.chinamobile.com/PLTV/88888888/224/3221225690/1/index.m3u8?fmt=ts2hls'
    }
  ]
}
