
export default {
  name: '音乐广播',
  list: [{
    logo: 'https://media.idownloadblog.com/wp-content/uploads/2014/07/iTunes-12-icon.png',
    name: 'Apple Music Radio',
    url: 'http://149.129.100.78:8000/iradio'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/音乐之声.png',
    name: 'CNR音乐之声',
    url: 'http://ngcdn003.cnr.cn/live/yyzs/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/经典音乐广播.png',
    name: 'CNR经典音乐广播',
    url: 'http://ngcdn004.cnr.cn/live/dszs/index.m3u8'
  },

  {
    logo: 'http://cnvod.cnr.cn/audio2017/ondemand/img/1100/20191227/1577439531060.png',
    name: 'CRI HIT FM (北京)',
    url: 'http://satellitepull.cnr.cn/live/wxgjlxyy/playlist.m3u8'
  },
  {
    logo: 'http://cnvod.cnr.cn/audio2017/ondemand/img/1100/20191227/1577439531060.png',
    name: 'CRI HIT FM (广州)',
    url: 'http://satellitepull.cnr.cn/live/wxhitfm/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/北京经典969.png',
    name: '北京经典969',
    url: 'http://live.funhillrm.com/4/sd/live.m3u8'
  },
  {
    logo: 'https://pic.qtfm.cn/2019/0830/20190830075424.jpeg',
    name: '旅游之声',
    url: 'http://starfish.bj.aliyun.007.qingting.fm:8000/fm98.7lvyouzhisheng'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/陕西音乐广播.png',
    name: '陕西音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxsxxyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/好听1055.png',
    name: '陕西青少广播·好听1055',
    url: 'http://satellitepull.cnr.cn/live/wxsxxqcgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/苏州音乐广播.png',
    name: '苏州都市音乐广播',
    url: 'http://audio-live.radio.cn/live/szyy1/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/山东音乐广播.png',
    name: '山东音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxsdyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/山东经典音乐广播.png',
    name: '山东经典音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxsdshxxgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/安徽音乐广播.png',
    name: '安徽音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxahyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/宁波音乐广播.png',
    name: '宁波音乐广播',
    url: 'http://ihzlh.linker.cc/ihzlh/zjnb_ts02_986.m3u8'
  },

  {
    logo: 'http://pic.qingting.fm/2021/0809/20210809021518.png',
    name: '湖南音乐之声·芒果音乐台',
    url: 'http://a.live.hnradio.com/yypd/radio120k_yypd.m3u8?auth_key=1588751172-0-0-d97b858279c1c86650172b9913ea4af2'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/摩登音乐台.png',
    name: '湖南文艺广播·摩登音乐台',
    url: 'http://satellitepull.cnr.cn/live/wx32hunwygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/惠州音乐广播.JPG',
    name: '惠州音乐广播',
    url: 'http://stream.hztvmg.com/test17/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/宜昌广播.png',
    name: '宜昌音乐生活广播',
    url: 'http://playfmaliyun.3xgd.com/fm/qct.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/江西旅游广播.png',
    name: '江西旅游广播iFM97.4',
    url: 'http://live02.jxtvcn.com.cn/live-jxtvcn/jxtv_pyhgb_aac.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/江西故事广播.png',
    name: '江西故事广播·潮台969',
    url: 'http://live02.jxtvcn.com.cn/live-jxtvcn/jxtv_myfm969_aac.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/山西音乐广播.png',
    name: '山西音乐广播',
    url: 'https://radiolive.sxrtv.com/live/yinyue/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/内蒙古音乐之声.png',
    name: '内蒙古音乐之声',
    url: 'http://satellitepull.cnr.cn/live/wx32nmgyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/湖北经典音乐广播.png',
    name: '湖北经典音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hubyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/甘肃青春调频.png',
    name: '甘肃青春调频',
    url: 'http://satellitepull.cnr.cn/live/wxgsqcgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/甘肃都市调频.png',
    name: '甘肃都市调频',
    url: 'http://satellitepull.cnr.cn/live/wxgsdstb/playlist.m3u8'
  },
  {
    logo: 'https://pic.qingting.fm/2015/0225/20150225111148873.png',
    name: '广西音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gxwygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/广西女主播.png',
    name: '广西经济广播·女主播电台',
    url: 'http://satellitepull.cnr.cn/live/wx32gxjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/云南音乐广播.png',
    name: '云南音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxynyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/四川城市之音.png',
    name: '四川城市之音',
    url: 'http://satellitepull.cnr.cn/live/wxsccszs/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/四川岷江音乐.png',
    name: '四川岷江音乐',
    url: 'http://satellitepull.cnr.cn/live/wxscmjyyt/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/厦门音乐广播.png',
    name: '厦门音乐广播',
    url: 'http://live2.kxm.xmtv.cn/aac_xmyy/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/楚天音乐广播.png',
    name: '楚天音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hubctyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/江苏音乐广播.png',
    name: '江苏音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jsyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/江苏经典流行音乐广播.png',
    name: '江苏经典流行音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jsjdlxyy/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/动听968.png',
    name: '浙江音乐调频·动听968',
    url: 'http://satellitepull.cnr.cn/live/wxzj968/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/河北音乐广播.png',
    name: '河北音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxhebyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/广东音乐之声.png',
    name: '广东音乐之声',
    url: 'http://satellitepull.cnr.cn/live/wxgdyyzs/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/深圳飞扬971.png',
    name: '深圳飞扬971',
    url: 'http://satellitepull.cnr.cn/live/wxszfy971/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/深圳生活942.png',
    name: '深圳生活942',
    url: 'http://satellitepull.cnr.cn/live/wxszsjcgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/海南音乐广播.png',
    name: '海南音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxhainyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/贵州音乐广播.png',
    name: '贵州音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gzyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/贵州都市广播.png',
    name: '贵州都市广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gzqcgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/宁夏音乐广播.png',
    name: '宁夏音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxnxyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/重庆音乐广播.png',
    name: '重庆音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxcqyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/江西音乐广播.png',
    name: '江西音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jiangxyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/黑龙江音乐广播.png',
    name: '黑龙江音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hljyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/福建汽车音乐广播.png',
    name: '福建汽车音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32fjdnyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/哈尔滨古典音乐广播.png',
    name: '哈尔滨古典音乐广播',
    url: 'http://pull.hrbtv.net/tv/12.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/福州音乐广播.png',
    name: '福州音乐广播',
    url: 'http://live.zohi.tv/audio/s10001-FM893/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/成都经典946.png',
    name: '成都文化休闲广播·radio946',
    url: 'http://149.129.100.78:88/cdgb.php?id=433-20'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/海口音乐广播.png',
    name: 'WHIZ Radio 海口音乐广播',
    url: 'http://starfish.sz.aliyun.005.qingting.fm:8000/haikouSIMULRADIO'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/辽宁经典音乐广播.png',
    name: '辽宁经典音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wxlnwygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/青海交通音乐广播.png',
    name: '青海交通音乐广播',
    url: 'http://satellitepull.cnr.cn/live/wx32qhjtyygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/北京音乐广播.png',
    name: '北京音乐广播',
    url: 'http://audiolive2.rbc.cn:1935/live/fm974/96K/tzwj_video.m3u8'
  },
  {
    logo: 'https://pic.qingting.fm/2017/0501/20170501063612.jpeg',
    name: '武汉音乐广播',
    url: 'http://stream.appwuhan.com/yypdzb/sd/live.m3u8'
  },
  {
    logo: 'https://pic.qingting.fm/2015/0521/20150521164427620.png',
    name: '武汉青少广播·M-radio',
    url: 'http://stream.appwuhan.com/sepdzb/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/乐动1056.png',
    name: 'Heart FM 乐动1056',
    url: 'http://starfish.bj.aliyun.006.qingting.fm:8000/shenyangjingdianyinle'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/长沙城市之声.png',
    name: '长沙城市之声',
    url: 'http://115.238.238.218/zhcs_radio/cszs1017__redirect__20573.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/长沙音乐广播.png',
    name: '长沙音乐广播',
    url: 'http://222.186.61.82/zhcs_radio/jjgb886__redirect__20573.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/长沙交通广播.png',
    name: '长沙交通广播',
    url: 'http://115.238.238.218/zhcs_radio/jtgb1061__redirect__20573.m3u8'
  }
  ]
}
