
export default {
  name: '综合广播',
  list: [{
    logo: 'http://cnvod.cnr.cn/audio2017/ondemand/img/1100/20200306/1583464064428.jpg',
    name: 'CRI环球资讯广播',
    url: 'http://satellitepull.cnr.cn/live/wxhqzx01/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/华语环球广播.png',
    name: 'CRI华语环球广播',
    url: 'http://sk.cri.cn/hyhq.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/chinaplusradio.png',
    name: 'CRI英语环球广播 China Plus Radio',
    url: 'http://sk.cri.cn/am846.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/chinaplusradio.png',
    name: 'CRI外语教学广播 China Plus Radio',
    url: 'http://sk.cri.cn/am1008.m3u8'
  },
  {
    logo: 'https://pic.qtfm.cn/sso/198/1564021782775_bUoQXgHrn.jpeg',
    name: 'CRI南海之声',
    url: 'http://sk.cri.cn/nhzs.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/轻松调频.png',
    name: 'CRI轻松调频',
    url: 'http://sk.cri.cn/915.m3u8'
  },
  {
    logo: 'https://cdn-radiotime-logos.tunein.com/p38821d.png',
    name: 'CRI粤语国际',
    url: 'http://sk.cri.cn/hxfh.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/中国之声.png',
    name: 'CNR中国之声',
    url: 'http://ngcdn001.cnr.cn/live/zgzs/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/经济之声.png',
    name: 'CNR经济之声',
    url: 'http://ngcdn002.cnr.cn/live/jjzs/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/文艺之声.png',
    name: 'CNR文艺之声',
    url: 'http://audiows010.cnr.cn/live/wyzs192/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/阅读之声.png',
    name: 'CNR阅读之声',
    url: 'http://ngcdn014.cnr.cn/live/ylgb/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/台海之声.png',
    name: 'CNR台海之声',
    url: 'http://ngcdn005.cnr.cn/live/zhzs/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/乡村之声.png',
    name: 'CNR乡村之声',
    url: 'http://ngcdn017.cnr.cn/live/xczs/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/老年之声.png',
    name: 'CNR老年之声',
    url: 'http://ngcdn011.cnr.cn/live/lnzs/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/cnr民族之声.png',
    name: 'CNR民族之声',
    url: 'http://ngcdn009.cnr.cn/live/mzzs/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/cnr香港之声.png',
    name: 'CNR香港之声',
    url: 'http://ngcdn008.cnr.cn/live/xgzs/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/大湾区之声.png',
    name: 'CNR大湾区之声',
    url: 'http://ngcdn007.cnr.cn/live/hxzs/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/cnr神州之声.png',
    name: 'CNR神州之声',
    url: 'http://ngcdn006.cnr.cn/live/szzs/index.m3u8'
  },
  {
    logo: 'https://pic.qtfm.cn/2017/0208/20170208175843902.jpg',
    name: 'CNR中国交通广播',
    url: 'http://ngcdn016.cnr.cn/live/gsgljtgb/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/长三角之声.png',
    name: '上海长三角之声',
    url: 'http://satellitepull.cnr.cn/live/wx32dfgbdt/playlist.m3u8'
  },
  {
    logo: 'https://upload.ajmide.com/p/image/202101/14/142407-nN2AikHyrt1610609292338_1915x1915.jpg',
    name: '上海第一财经广播',
    url: 'http://satellitepull.cnr.cn/live/wx32dycjgb/playlist.m3u8'
  },
  {
    logo: 'https://pic.qingting.fm/2020/0701/20200701052348.png',
    name: '天津静海区综合广播·交通广播网',
    url: 'http://starfish.bj.aliyun.001.qingting.fm:8000/jingdianFM1008'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/海峡之声文化生活广播.png',
    name: '海峡之声·文化生活广播',
    url: 'mmst://27.151.117.209/996'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/海峡之声新闻广播.png',
    name: '海峡之声·新闻广播',
    url: 'http://www.vos.com.cn/live/liveNew/800k/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/海峡之声闽南语广播.png',
    name: '海峡之声·闽南话广播',
    url: 'http://47.107.255.25:1935/live/liveMn/800k/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/中国华艺广播.png',
    name: '中国华艺广播',
    url: 'http://47.107.255.25:1935/live/chbc/800k/tzwj_video.m3u8'
  },
  {
    logo: 'https://wx3.sinaimg.cn/mw2000/74c40dealy8gq8y3155j1j20u00u0myp.jpg',
    name: '湖南潇湘之声',
    url: 'http://satellitepull.cnr.cn/live/wx32hunyygb/playlist.m3u8'
  },
  {
    logo: 'https://img-ossimg.ajmide.com/program/29834-1GFeSt_331x331.jpg@350w_1l',
    name: '湖南经济广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hunjjgb/playlist.m3u8'
  },
  {
    logo: 'https://pic.qingting.fm/2021/0831/20210831043119.jpeg',
    name: '湖南旅游广播·PoPoPod播客电台',
    url: 'http://satellitepull.cnr.cn/live/wx32hunlygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/湖南交通广播.png',
    name: '湖南交通广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hunjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/金鹰955.png',
    name: '湖南金鹰之声',
    url: 'http://satellitepull.cnr.cn/live/wx32955/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/湖南新闻综合广播.png',
    name: '湖南综合广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hunxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/京津冀之声.png',
    name: '北京京津冀之声',
    url: 'http://audiolive.rbc.cn:1935/live/fm945/96K/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/北京新闻广播.png',
    name: '北京新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wxbjxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/北京交通广播.png',
    name: '北京交通广播',
    url: 'http://audiolive.rbc.cn:1935/live/fm1039/96K/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/北京青年广播.png',
    name: '北京青年广播',
    url: 'http://audiolive.rbc.cn:1935/live/am927/96K/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/北京外语广播.png',
    name: '北京外语广播',
    url: 'http://audiolive.rbc.cn:1935/live/am774/96K/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/北京城市广播.png',
    name: '北京城市广播·副中心之声',
    url: 'http://audiolive.rbc.cn:1935/live/fm1073/96K/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/北京文艺广播.png',
    name: '北京文艺广播',
    url: 'http://123.56.16.201:1935/live/fm876/96K/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/北京故事广播.png',
    name: '北京故事广播',
    url: 'http://123.56.16.201:1935/live/am603/96K/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/北京体育广播.png',
    name: '北京体育广播',
    url: 'http://audiolive.rbc.cn:1935/live/fm1025/96K/tzwj_video.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江苏故事广播.png',
    name: '江苏故事广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jsgsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江苏新闻综合广播.png',
    name: '江苏新闻综合广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jsxwzhgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江苏新闻广播.png',
    name: '江苏新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jsxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江苏文艺广播.png',
    name: '江苏文艺广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jswygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/金陵之声.png',
    name: '江苏金陵之声',
    url: 'http://satellitepull.cnr.cn/live/wx32jsqctp/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江苏交通广播.png',
    name: '江苏交通广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jsjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江苏财经广播.png',
    name: '江苏财经广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jscjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江苏健康广播.png',
    name: '江苏健康广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jsjkgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/苏州生活广播.png',
    name: '苏州生活广播',
    url: 'http://audio-live.radio.cn/live/szsh1/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/苏州儿童广播.png',
    name: '苏州儿童广播',
    url: 'http://audio-live.radio.cn/live/szet1/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/苏州综合广播.png',
    name: '苏州综合广播',
    url: 'http://audio-live.radio.cn/live/szxwzh1/playlist.m3u8'
  },
  {
    logo: 'https://pic.qtfm.cn/sso/198/1631607649319_Vd7ueakoB.jpeg',
    name: '苏州交通经济广播',
    url: 'http://audio-live.radio.cn/live/szjtjj1/playlist.m3u8'
  },
  {
    logo: 'http://pic.qingting.fm/2018/0212/20180212113048425.jpg',
    name: '苏州戏曲广播',
    url: 'http://audio-live.radio.cn/live/szxq1/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/安徽之声.png',
    name: '安徽之声',
    url: 'http://satellitepull.cnr.cn/live/wxahxxgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/安徽城市之声.png',
    name: '安徽生活广播·城市之声',
    url: 'http://satellitepull.cnr.cn/live/wxahshgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/安徽经济广播.png',
    name: '安徽经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxahjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/安徽交通广播.png',
    name: '安徽交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxahjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/安徽高速之声.png',
    name: '安徽旅游广播·高速之声',
    url: 'http://satellitepull.cnr.cn/live/wxahlygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/安徽戏曲广播.png',
    name: '安徽戏曲广播·动听995',
    url: 'http://satellitepull.cnr.cn/live/wxahxqgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/安徽故事广播.png',
    name: '安徽故事广播',
    url: 'http://satellitepull.cnr.cn/live/wxahxspsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/湖北之声.png',
    name: '湖北之声',
    url: 'http://satellitepull.cnr.cn/live/wx32hubzsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/楚天交通广播.png',
    name: '楚天交通广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hubctjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/湖北亲子广播.png',
    name: '湖北亲子广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hubfnetgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/湖北生活广播.png',
    name: '湖北生活广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hubczshgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/湖北城市之声.png',
    name: '湖北城市之声',
    url: 'http://satellitepull.cnr.cn/live/wx32hubjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/湖北资讯广播.png',
    name: '湖北资讯广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hubzxgb/playlist.m3u8'
  },
  {
    logo: 'http://pic.qingting.fm/2015/0617/2015061718015278.jpg',
    name: '武汉新闻广播',
    url: 'http://stream.appwuhan.com/xwpdzb/sd/live.m3u8'
  },
  {
    logo: 'https://pic.qingting.fm/2018/0101/20180101120944.jpeg',
    name: '武汉交通广播',
    url: 'http://stream.appwuhan.com/jtpdzb/sd/live.m3u8'
  },
  {
    logo: 'https://pic.qingting.fm/2015/1125/20151125214015766.jpg',
    name: '武汉经济广播',
    url: 'http://stream.appwuhan.com/cjpdzb/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/河北文艺广播.png',
    name: '河北文艺广播',
    url: 'http://satellitepull.cnr.cn/live/wxhebwygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/河北综合广播.png',
    name: '河北综合广播',
    url: 'http://satellitepull.cnr.cn/live/wxhebzhgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/河北交通广播.png',
    name: '河北交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxhebjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/河北故事广播.png',
    name: '河北经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxhebjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/河北生活广播.png',
    name: '河北生活广播',
    url: 'http://satellitepull.cnr.cn/live/wxhebshgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/福建新闻广播.png',
    name: '福建新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wx32fjxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/福建都市广播.png',
    name: '福建都市广播·私家车987',
    url: 'http://satellitepull.cnr.cn/live/wx32fjdndsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/福建交通广播.png',
    name: '福建交通广播',
    url: 'http://satellitepull.cnr.cn/live/wx32fjdnjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/福建经济广播.png',
    name: '福建经济广播',
    url: 'http://satellitepull.cnr.cn/live/wx32fjdnjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/福建东南广播.png',
    name: '福建东南广播',
    url: 'http://satellitepull.cnr.cn/live/wx32fjdngb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江西新闻广播.png',
    name: '江西综合新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jiangxxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江西都市广播.png',
    name: '江西都市广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jiangxdsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江西交通广播.png',
    name: '江西信息交通广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jiangxjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江西民生广播.png',
    name: '江西民生广播',
    url: 'http://satellitepull.cnr.cn/live/wx32jiangxmsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/江西农村广播.png',
    name: '江西农村广播·绿色985',
    url: 'http://satellitepull.cnr.cn/live/wx32jiangxncgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山东综合广播.png',
    name: '山东综合广播',
    url: 'http://live-hls.ihzlh.linker.cc/ihzlh/sd_ts01_95.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山东文艺广播.png',
    name: '山东文艺广播',
    url: 'http://live-hls.ihzlh.linker.cc/ihzlh/sd_ts03_975.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山东交通广播.png',
    name: '山东交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxsdjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山东体育休闲广播.png',
    name: '山东体育休闲广播',
    url: 'http://satellitepull.cnr.cn/live/wxsdtyxxgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山东经济广播.png',
    name: '山东经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxsdjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山东乡村广播.png',
    name: '山东乡村广播',
    url: 'http://satellitepull.cnr.cn/live/wxsdxcgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/绍兴之声.png',
    name: '绍兴之声',
    url: 'http://live.shaoxing.com.cn/audio/s10001-xw1/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/绍兴交通广播.png',
    name: '绍兴交通广播',
    url: 'http://live.shaoxing.com.cn/audio/s10001-jt2/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/陕西交通广播.png',
    name: '陕西交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxsxxjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/陕西经济广播.png',
    name: '陕西经济广播·896汽车调频',
    url: 'http://satellitepull.cnr.cn/live/wxsxxjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/陕西都市广播.png',
    name: '陕西都市广播·陕广新闻',
    url: 'http://satellitepull.cnr.cn/live/wxsxxdsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/陕西农村广播.png',
    name: '陕西农村广播',
    url: 'http://satellitepull.cnr.cn/live/wxsxxncgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/陕西戏曲广播.png',
    name: '陕西戏曲广播',
    url: 'http://satellitepull.cnr.cn/live/wxsxxxqgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/陕西新闻广播.png',
    name: '陕西新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wxsxxxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/浙江之声.png',
    name: '浙江之声',
    url: 'http://satellitepull.cnr.cn/live/wxzjzs/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/浙江经济广播.png',
    name: '浙江经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxzjjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/浙江交通之声.png',
    name: '浙江交通之声',
    url: 'http://satellitepull.cnr.cn/live/wxzjjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/浙江民生广播.png',
    name: '浙江民生资讯广播',
    url: 'http://satellitepull.cnr.cn/live/wxzjmsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/浙江城市之声.png',
    name: '浙江城市之声',
    url: 'http://satellitepull.cnr.cn/live/wxzjcszs/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/浙江旅游之声.png',
    name: '浙江旅游之声',
    url: 'http://satellitepull.cnr.cn/live/wxzj1045/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/重庆文艺广播.png',
    name: '重庆文艺广播',
    url: 'http://satellitepull.cnr.cn/live/wxcqwygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/重庆经济广播.png',
    name: '重庆经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxcqjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/重庆之声.png',
    name: '重庆之声',
    url: 'http://satellitepull.cnr.cn/live/wxcqxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/重庆都市广播.png',
    name: '重庆都市广播·私家车938',
    url: 'http://satellitepull.cnr.cn/live/wxcqdsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/重庆交通广播.png',
    name: '重庆交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxcqjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/宁波新闻综合广播.png',
    name: '宁波新闻综合广播',
    url: 'http://ihzlh.linker.cc/ihzlh/zjnb_ts04_920.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/宁波老少广播.png',
    name: '宁波老少广播·阳光904',
    url: 'http://ihzlh.linker.cc/ihzlh/zjnb_ts05_904.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/宁波交通广播.png',
    name: '宁波交通广播',
    url: 'http://ihzlh.linker.cc/ihzlh/zjnb_ts01_939.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/宁波经济广播.png',
    name: '宁波经济广播',
    url: 'http://ihzlh.linker.cc/ihzlh/zjnb_ts03_1029.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/广东新闻广播.png',
    name: '广东新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wxgdxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/广东股市广播.png',
    name: '广东股市广播',
    url: 'http://satellitepull.cnr.cn/live/wxgdgsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/广东文体广播.png',
    name: '广东文体广播',
    url: 'http://satellitepull.cnr.cn/live/wxgdwtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/广东城市之声.png',
    name: '广东城市之声',
    url: 'http://satellitepull.cnr.cn/live/wxgdcszs/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/广东南粤之声.png',
    name: '广东南粤之声',
    url: 'http://satellitepull.cnr.cn/live/wxnyzs/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/珠江经济广播.png',
    name: '珠江经济台',
    url: 'http://satellitepull.cnr.cn/live/wxgdzjjjt/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/南方生活广播.png',
    name: '南方生活广播',
    url: 'http://satellitepull.cnr.cn/live/wxgdnfshgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/羊城交通广播.png',
    name: '羊城交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxgdycjtt/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/深圳交通广播.png',
    name: '深圳交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxszjjpl/playlist.m3u8'
  },
  {
    logo: 'https://pic.qingting.fm/2021/0810/20210810093440.jpeg',
    name: '惠州综合广播',
    url: 'http://stream.hztvmg.com/gbtest2/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/海南新闻广播.png',
    name: '海南新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wxhainxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/海南交通广播.png',
    name: '海南交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxhainjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/四川经济广播.png',
    name: '四川经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxscjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/四川文艺广播.png',
    name: '四川文艺广播',
    url: 'http://satellitepull.cnr.cn/live/wxscwygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/四川旅游生活广播.png',
    name: '四川旅游生活广播',
    url: 'http://satellitepull.cnr.cn/live/wxsclyshgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/四川交通广播.png',
    name: '四川交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxscjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/四川之声.png',
    name: '四川综合广播四川之声',
    url: 'http://satellitepull.cnr.cn/live/wxsczhgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/四川私家车广播.png',
    name: '四川私家车广播',
    url: 'http://satellitepull.cnr.cn/live/wxsc925/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/成都经济广播.png',
    name: '成都经济广播',
    url: 'http://149.129.100.78:88/cdgb.php?id=433-19'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/成都交通文艺广播.png',
    name: '成都交通文艺广播',
    url: 'http://149.129.100.78:88/cdgb.php?id=433-22'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/成都故事广播.png',
    name: '成都故事广播',
    url: 'http://149.129.100.78:88/cdgb.php?id=433-40'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/成都新闻广播.png',
    name: '成都新闻广播',
    url: 'http://149.129.100.78:88/cdgb.php?id=433-21'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/贵州经济广播.png',
    name: '贵州经济广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gzjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/贵州旅游广播.png',
    name: '贵州旅游广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gzlygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/贵州故事广播.png',
    name: '贵州故事广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gzgsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/贵州综合广播.png',
    name: '贵州综合广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gzwxwzhgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/吉林新闻综合广播.png',
    name: '吉林新闻综合广播',
    url: 'http://satellitepull.cnr.cn/live/wxjlxwzhgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/吉林经济广播.png',
    name: '吉林经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxjljjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/吉林交通广播.png',
    name: '吉林交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxjljtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/吉林乡村广播.png',
    name: '吉林乡村广播',
    url: 'http://satellitepull.cnr.cn/live/wxjlxcgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/延边新闻广播.png',
    name: '延边新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wxybxwgb/playlist.m3u8'
  },
  {
    logo: '',
    name: '延边文艺生活广播',
    url: '"http://satellitepull.cnr.cn/live/wxybwyshgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/辽宁之声.png',
    name: '辽宁之声',
    url: 'http://satellitepull.cnr.cn/live/wxlnzhgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/辽宁乡村广播.png',
    name: '辽宁乡村广播',
    url: 'http://satellitepull.cnr.cn/live/wxlnxcgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/辽宁经济广播.png',
    name: '辽宁经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxlnjjtb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/辽宁交通广播.png',
    name: '辽宁交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxlnjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/厦门综合广播.png',
    name: '厦门综合广播',
    url: 'https://live2.kxm.xmtv.cn/aac_zhgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/厦门旅游广播.png',
    name: '厦门旅游广播',
    url: 'https://live2.kxm.xmtv.cn/aac_xmly/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/厦门经济交通广播.png',
    name: '厦门经济交通广播',
    url: 'https://live2.kxm.xmtv.cn/aac_xmjjjt/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/闽南之声.png',
    name: '厦门闽南之声',
    url: 'https://live2.kxm.xmtv.cn/aac_mnzs/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/福州左海之声.png',
    name: '福州左海之声',
    url: 'http://live.zohi.tv/audio/s10001-FM901/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/福州交通广播.png',
    name: '福州交通之声',
    url: 'http://live.zohi.tv/audio/s10001-FM876/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/福州新闻广播.png',
    name: '福州新闻广播',
    url: 'http://live.zohi.tv/audio/s10001-FM944/index.m3u8'
  },
  {
    logo: '',
    name: '雅安综合广播',
    url: 'https://m3u8channel.wuxianyaan.com/cms/audios/nmip-media/audiolive/audio10/playlist.m3u8'
  },
  {
    logo: '',
    name: '雅安交通旅游广播',
    url: 'https://m3u8channel.wuxianyaan.com/cms/audios/nmip-media/audiolive/audio12/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/徐州新闻综合广播.png',
    name: '徐州新闻综合广播',
    url: 'http://stream1.huaihai.tv/aac_gbfm93/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/黑龙江新闻广播.png',
    name: '黑龙江新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hljxwgb/playlist.m3u8'
  },
  {
    logo: 'https://pic.qtfm.cn/2020/0405/20200405071154.jpeg',
    name: '黑龙江交通广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hljjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/黑龙江都市女性广播.png',
    name: '黑龙江都市女性广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hljnxgb/playlist.m3u8'
  },
  {
    logo: 'https://pic.qtfm.cn/2020/0405/20200405071145.png',
    name: '黑龙江都生活广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hljsjcgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/黑龙江高校广播.png',
    name: '黑龙江高校广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hljgxgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/黑龙江老年少儿广播.png',
    name: '黑龙江老年少儿广播·爱家调频',
    url: 'http://satellitepull.cnr.cn/live/wx32hljajgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/黑龙江乡村广播.png',
    name: '黑龙江乡村广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hljxcgb/playlist.m3u8'
  },
  {
    logo: '',
    name: '黑龙江朝鲜语广播',
    url: 'http://satellitepull.cnr.cn/live/wx32hljcygb/playlist.m3u8'
  },
  {
    logo: 'https://pic.qtfm.cn/2011/0926/20110926100350125.jpg',
    name: '内蒙古农村牧区广播绿野之声',
    url: 'http://satellitepull.cnr.cn/live/wx32nmglyzs/playlist.m3u8'
  },
  {
    logo: 'http://pic.qingting.fm/2011/0926/20110926100610683.jpg',
    name: '内蒙古新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmghyzhxwgb/playlist.m3u8'
  },
  {
    logo: 'http://pic.qingting.fm/2015/0804/20150804105202673.jpg',
    name: '内蒙古经济生活广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmgjjgb/playlist.m3u8'
  },
  {
    logo: 'http://pic.qingting.fm/2011/0926/20110926100411244.jpg',
    name: '内蒙古评书曲艺广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmgpsqy/playlist.m3u8'
  },
  {
    logo: 'http://pic.qingting.fm/2015/0324/20150324134256614.jpg',
    name: '内蒙古蒙语对外广播草原之声',
    url: 'http://satellitepull.cnr.cn/live/wx32nmgdwgb/playlist.m3u8'
  },
  {
    logo: 'http://pic.qingting.fm/2011/0926/20110926100552676.jpg',
    name: '内蒙古蒙语新闻综合广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmgmygb/playlist.m3u8'
  },
  {
    logo: '',
    name: '锡林郭勒汉语广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmgxlglhygb/playlist.m3u8'
  },
  {
    logo: '',
    name: '锡林郭勒蒙语广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmgxlglmygb/playlist.m3u8'
  },
  {
    logo: '',
    name: '阿拉善汉语广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmgalshygb/playlist.m3u8'
  },
  {
    logo: '',
    name: '阿拉善蒙语广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmgalsmygb/playlist.m3u8'
  },
  {
    logo: '',
    name: '呼伦贝尔汉语广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmghlbehygb/playlist.m3u8'
  },
  {
    logo: '',
    name: '呼伦贝尔蒙语广播',
    url: 'http://satellitepull.cnr.cn/live/wx32nmghlbemygb/playlist.m3u8'
  },
  {
    logo: '',
    name: '新疆新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wxxjxwgb/playlist.m3u8'
  },
  {
    logo: '',
    name: '新疆交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxxjjtgb/playlist.m3u8'
  },
  {
    logo: '',
    name: '新疆私家车广播',
    url: 'http://satellitepull.cnr.cn/live/wxxjsjcgb/playlist.m3u8'
  },
  {
    logo: '',
    name: '新疆绿色广播',
    url: 'http://satellitepull.cnr.cn/live/wxxjlsgb/playlist.m3u8'
  },
  {
    logo: '',
    name: '西藏都市生活广播',
    url: 'http://satellitepull.cnr.cn/live/wxxzdsshgb/playlist.m3u8'
  },
  {
    logo: '',
    name: '西藏对外交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxxzdwjtgb/playlist.m3u8'
  },
  {
    logo: '',
    name: '西藏汉语广播',
    url: 'http://satellitepull.cnr.cn/live/wxxzhygb/playlist.m3u8'
  },
  {
    logo: '',
    name: '西藏藏语广播',
    url: 'http://satellitepull.cnr.cn/live/wxxzzygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/甘肃交通广播.png',
    name: '甘肃交通广播',
    url: 'http://satellitepull.cnr.cn/live/wxgsjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/甘肃农村广播.png',
    name: '甘肃农村广播',
    url: 'http://satellitepull.cnr.cn/live/wxgsncgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/甘肃经济广播.png',
    name: '甘肃经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxgshhzs/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/甘肃新闻综合广播.png',
    name: '甘肃新闻综合广播',
    url: 'http://satellitepull.cnr.cn/live/wxgsxwzhgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/广西教育广播.png',
    name: '广西教育广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gbjyshgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/广西新闻广播.png',
    name: '广西综合广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gxrmgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/广西北部湾之声.png',
    name: '广西北部湾之声',
    url: 'http://satellitepull.cnr.cn/live/wx32gxdwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/广西交通广播.png',
    name: '广西交通广播',
    url: 'http://satellitepull.cnr.cn/live/wx32gxjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/云南新闻广播.png',
    name: '云南新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wxynxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/云南经济广播.png',
    name: '云南经济广播·私家车电台',
    url: 'http://satellitepull.cnr.cn/live/wxynjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/云南交通之声.png',
    name: '云南交通之声',
    url: 'http://satellitepull.cnr.cn/live/wxynjtgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/云南旅游广播.png',
    name: '云南旅游广播·香格里拉之声',
    url: 'http://satellitepull.cnr.cn/live/wxxgllzs/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/云南民族广播.png',
    name: '云南民族广播',
    url: 'http://satellitepull.cnr.cn/live/wxynmzgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/云南国际广播.png',
    name: '云南国际广播',
    url: 'http://satellitepull.cnr.cn/live/wxynsegb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/云南教育广播.png',
    name: '云南教育广播·知道分子频道',
    url: 'http://satellitepull.cnr.cn/live/wxynjygb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/宁夏经济广播.png',
    name: '宁夏经济广播',
    url: 'http://satellitepull.cnr.cn/live/wxnxjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/宁夏新闻广播.png',
    name: '宁夏新闻广播',
    url: 'http://satellitepull.cnr.cn/live/wxnxxwgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/宁夏旅游广播.png',
    name: '宁夏旅游广播',
    url: 'http://satellitepull.cnr.cn/live/wxnxdsgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/青海新闻综合广播.png',
    name: '青海新闻综合广播',
    url: 'http://satellitepull.cnr.cn/live/wx32qhwxzhgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/青海经济广播.png',
    name: '青海经济广播',
    url: 'http://satellitepull.cnr.cn/live/wx32qhjjgb/playlist.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/世界华声.png',
    name: '世界华声',
    url: 'http://sk.cri.cn/hxfh.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/益阳之声.png',
    name: '益阳之声',
    url: 'http://fms.yyrtv.com:82/hls-live/livepkgr/_definst_/liveevent/fm997.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/益阳交通频道.png',
    name: '益阳交通频道·汽车881',
    url: 'http://fms.yyrtv.com:82/hls-live/livepkgr/_definst_/liveevent/fm881.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/开州之声.png',
    name: '开州之声',
    url: 'http://183.64.174.171:10124/tlgb.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/镇江文艺广播.png',
    name: '镇江文艺广播·翡翠963',
    url: 'http://live.zjmc.tv/6/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/镇江交通广播.png',
    name: '镇江交通广播',
    url: 'http://live.zjmc.tv/5/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/镇江综合广播.png',
    name: '镇江综合广播',
    url: 'http://live.zjmc.tv/4/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/镇江经济广播.png',
    name: '镇江经济广播',
    url: 'http://live.zjmc.tv/7/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山西健康之声.png',
    name: '山西健康之声广播',
    url: 'rtmp://radiolive.sxrtv.com/live/jiankang'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山西农村广播.png',
    name: '山西农村广播',
    url: 'rtmp://radiolive.sxrtv.com/live/nongcun'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/山西文艺广播.png',
    name: '山西文艺广播·好听1015',
    url: 'rtmp://radiolive.sxrtv.com/live/wenyi'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山西交通广播.png',
    name: '山西交通广播',
    url: 'rtmp://radiolive.sxrtv.com/live/jiaotong'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山西经济广播.png',
    name: '山西经济广播',
    url: 'rtmp://radiolive.sxrtv.com/live/jingji'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山西综合广播.png',
    name: '山西综合广播',
    url: 'rtmp://radiolive.sxrtv.com/live/zonghe'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/山西故事广播.png',
    name: '山西故事广播',
    url: 'rtmp://radiolive.sxrtv.com/live/gushi'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/宜昌广播.png',
    name: '宜昌新闻综合广播',
    url: 'http://playfmaliyun.3xgd.com/fm/xwt.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/musicradio/宜昌广播.png',
    name: '宜昌交通广播',
    url: 'http://playfmaliyun.3xgd.com/fm/jtt.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/欢乐调频.png',
    name: '欢乐调频',
    url: 'http://starfish.bj.aliyun.007.qingting.fm:8000/huanletiaopin'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/generalradio/房山电台.png',
    name: '房山电台',
    url: 'http://live.funhillrm.com/3/sd/live.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/cnr维吾尔语广播.png',
    name: '维吾尔语广播',
    url: 'http://ngcdn013.cnr.cn/live/wygb/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/cnr藏语广播.png',
    name: '藏语广播',
    url: 'http://ngcdn012.cnr.cn/live/zygb/index.m3u8'
  },
  {
    logo: 'https://gitee.com/radioer/transparentlogo/raw/master/cmgradio/cnr哈萨克语广播.png',
    name: '哈萨克语广播',
    url: 'http://ngcdn025.cnr.cn/live/hygb/index.m3u8'
  }
  ]
}
